import { useParams } from 'react-router-dom';

import { assertIsDefined } from '@/utils/assert';

import { useScenarioForm } from './useScenarioForm';

export const useIsLandPortionProject = (projectIdArg?: string) => {
  const form = useScenarioForm();

  const routeParams = useParams();
  const projectId = projectIdArg ?? routeParams.projectId;

  const [landPortionId] = form.getValues(['landPortionId']);
  assertIsDefined(landPortionId, 'landPortionId');
  return landPortionId === projectId;
};
