import { FC, PropsWithChildren } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { useUser } from '@/lib/auth';
import { paths } from '@/routing';
import { RequireAuth } from '@/routing/components/protections/require-auth/RequireAuth';

export const LandStewardSignedInRoute: FC = () => {
  return (
    <RequireAuth>
      <RequireLandStewardRole>
        <Outlet />
      </RequireLandStewardRole>
    </RequireAuth>
  );
};

const RequireLandStewardRole: FC<PropsWithChildren> = ({ children }) => {
  const user = useUser();

  const userType = user.membership.type;

  if (userType === MembershipWithOrganizationTypeEnum.buyer) {
    return <Navigate to={paths.buyer.restrictedAccess} replace />;
  }

  if (userType !== MembershipWithOrganizationTypeEnum.land_steward)
    throw new Error(
      `Expected "${MembershipWithOrganizationTypeEnum.land_steward}", but got invalid user type "${userType}"`,
    );

  return <>{children}</>;
};
