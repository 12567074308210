import { Outlet, Route, ScrollRestoration } from 'react-router-dom';

import { InvalidUrlError } from '@/components/invalid-url-error/InvalidUrlError';
import { useNavigationTracking } from '@/lib/analytics';
import { BuyerPageRoutes } from '@/pages/buyer/routing/BuyerPageRoutes';
import { ConfirmMailChangePage } from '@/pages/confirm-mail-change/confirm-mail-change.page';
import { LandStewardPageRoutes } from '@/pages/landsteward/routing/LandStewardPageRoutes';
import { paths } from '@/routing/routing';

import { ActivateAccountPage } from '../pages/activate-account/index.page';
import { ImpersonatePage } from '../pages/login/impersonate/impersonate.page';
import { LoginPage } from '../pages/login/index.page';
import { NotFoundPage } from '../pages/not-found';
import { ResetPasswordPage } from '../pages/reset-password/index.page';
import { NewPasswordPage } from '../pages/reset-password/new-password/index.page';
import { RequireAuth } from './components/protections/require-auth/RequireAuth';
import { RequireBoot } from './components/protections/require-boot/RequireBoot';
import { SkipLoggedInUser } from './components/protections/skip-logged-in-user/SkipLoggedInUser';
import { RedirectToInitialPage } from './components/redirect-to-initial-page/RedirectToInitialPage';

const NavigationTracking = () => {
  useNavigationTracking();
  return null;
};

export const pageRoutes = (
  <Route
    element={
      <>
        <RequireBoot />
        <ScrollRestoration />
        <NavigationTracking />
      </>
    }
  >
    <Route index element={<RedirectToInitialPage />} />
    <Route path={paths.activateAccount} element={<ActivateAccountPage />} />
    <Route path={paths.invalidUrl} element={<InvalidUrlError />} />
    <Route
      path={paths.confirmMailChange}
      element={
        <RequireAuth>
          <ConfirmMailChangePage />
        </RequireAuth>
      }
    />
    <Route
      path={paths.resetPassword}
      element={
        <SkipLoggedInUser>
          <Outlet />
        </SkipLoggedInUser>
      }
    >
      <Route index element={<ResetPasswordPage />} />
      <Route path={paths.newPassword} element={<NewPasswordPage />} />
    </Route>
    <Route
      element={
        <SkipLoggedInUser>
          <Outlet />
        </SkipLoggedInUser>
      }
    >
      <Route path={paths.login} element={<LoginPage />} />
    </Route>
    <Route path={paths.loginImpersonate} element={<ImpersonatePage />} />
    {BuyerPageRoutes}
    {LandStewardPageRoutes}
    <Route path='*' element={<NotFoundPage />} />
  </Route>
);
