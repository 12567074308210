import { toast } from 'component-library';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet } from 'react-router-dom';

import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';
import { paths } from '@/routing';
import { getProjectPermissions } from '@/utils/permissions/getProjectPermissions';

export const RequireProjectEditable: FC = () => {
  const { t } = useTranslation();

  const project = useProjectDetailById().data;
  const hasWritePermission = getProjectPermissions(project).includes('write');

  if (!hasWritePermission) {
    toast({
      toastId: 'project-is-locked-redirect-toast',
      title: t('landSteward.plot.lockedToasts.redirectAddEditLink'),
      type: 'error',
      autoClose: 10_000,
    });
    return <Navigate to={paths.landSteward.restrictedAccess} replace />;
  }

  return <Outlet />;
};
