import { PlotType } from '@/api/rest/resources/types/plot';

export const getSupportedPlotTypes = () => {
  return [PlotType.GRASSLAND, PlotType.CROPLAND, PlotType.FORESTRY];
};

export const getUnsupportedPlotTypes = () => {
  return Object.values(PlotType).filter((plotType) => !isSupportedPlotType(plotType));
};

export function isSupportedPlotType(plotType: PlotType) {
  const supportedPlotTypes = getSupportedPlotTypes();
  return supportedPlotTypes.some((type) => type === plotType);
}
