// TODO: MVP-3284 Rename folder to NCCard and delete original
import complianceEudrSrc from '@assets/images/compliance-eudr.png';
import complianceGreenhouseSrc from '@assets/images/compliance-greenhouse.png';
import complianceRainforestSrc from '@assets/images/compliance-rainforest.png';
import complianceSaiSrc from '@assets/images/compliance-sai.png';
import complianceSbtiSrc from '@assets/images/compliance-sbti.png';
import complianceTnfdSrc from '@assets/images/compliance-tnfd.png';
import {
  ButtonLink,
  cn,
  Divider,
  IconHalo,
  InfoPopover,
  Stack,
  StackProps,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from 'component-library';
import i18next from 'i18next';
import React, { createContext, FC, forwardRef, HTMLAttributes, PropsWithChildren, useContext, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { FactLike } from '@/api/rest/resources/conservation';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { ComplianceFrameworkLogo } from '@/config/constants';
import { useMembershipType } from '@/hooks/useMembershipType';
import { useScreenSize } from '@/hooks/useScreenSize';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { exhaustivenessCheck } from '@/utils/exhaustivenessCheck';
import { DateLike, getLatestDate } from '@/utils/formatting/date';

export type NCCardAnalysisType = 'total' | 'average';

type NCCardContextValue = {
  analysisType: NCCardAnalysisType;
  setAnalysisType: (anaylsisType: NCCardAnalysisType) => void;
};

export const NCCardContext = createContext<NCCardContextValue>({
  analysisType: 'total',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAnalysisType: () => {},
});

export const useNCCardContext = () => useContext(NCCardContext);

const ExplainerContext = createContext<[HTMLDivElement | null, (element: HTMLDivElement | null) => void]>([
  null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
]);

const ExplainerPortal = ({ children }: PropsWithChildren) => {
  const [explainer] = React.useContext(ExplainerContext);

  if (!explainer || !children) return null;

  return createPortal(children, explainer);
};

const LargeScreenExplainerOutlet = () => {
  const [, setExplainer] = useContext(ExplainerContext);
  const isSmallScreen = useScreenSize() === 'small';

  if (isSmallScreen) return null;

  return <div ref={(ref) => setExplainer(ref)} />;
};

const SmallScreenExplainerOutlet = () => {
  const [, setExplainer] = useContext(ExplainerContext);
  const isSmallScreen = useScreenSize() === 'small';

  if (!isSmallScreen) return null;

  return <Stack className='px-6 pb-6' ref={(ref) => setExplainer(ref)} />;
};

export type NCCardProps = HTMLAttributes<HTMLDivElement> & {
  explainer?: React.ReactNode;
};

export const NCCard = forwardRef<HTMLDivElement, NCCardProps>(
  ({ className, explainer, children, ...delegated }, ref) => {
    const [analysisType, setAnalysisType] = useState<NCCardContextValue['analysisType']>('total');
    const explainerState = React.useState<HTMLDivElement | null>(null);

    return (
      <NCCardContext.Provider value={{ analysisType, setAnalysisType }}>
        <ExplainerContext.Provider value={explainerState}>
          <div ref={ref} className={cn('rounded-2xl bg-white-100', className)} {...delegated}>
            {children}
          </div>
          {explainer && <ExplainerPortal>{explainer}</ExplainerPortal>}
        </ExplainerContext.Provider>
      </NCCardContext.Provider>
    );
  },
);
NCCard.displayName = 'NCCard';

export type NCCardHeaderProps = Omit<StackProps, 'icon' | 'title'> & {
  icon?: React.ReactNode;
  title?: React.ReactNode;
};

export const NCCardHeader: FC<NCCardHeaderProps> = forwardRef<HTMLDivElement, NCCardHeaderProps>(
  ({ title, icon, children, className, ...delegated }, ref) => {
    const isLargeScreen = useScreenSize() === 'large';

    return (
      <div ref={ref} className='full-bleed-x'>
        <Stack direction='row' spacing={4} centerMain className={cn('justify-between p-6', className)} {...delegated}>
          <Stack direction='row' spacing={4} className={cn('items-center', children ? 'lg:flex-[0_1_20%]' : 'flex-1')}>
            {!!icon && <IconHalo>{icon}</IconHalo>}
            <span className='typography-h3'>{title}</span>
          </Stack>
          {isLargeScreen && children}
          <Stack direction='row' centerMain spacing={8}>
            {!isLargeScreen && children}
            <LargeScreenExplainerOutlet />
          </Stack>
        </Stack>
        <Divider />
      </div>
    );
  },
);
NCCardHeader.displayName = 'NCCardHeader';

export type NCCard2SectionLayoutProps = HTMLAttributes<HTMLDivElement>;

export const NCCard2SectionLayout = forwardRef<HTMLDivElement, NCCard2SectionLayoutProps>(
  ({ className, ...delegated }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'divide-y divide-divider px-2',
          'sm:[&>*]:px-6',
          'md:grid md:w-auto md:grid-cols-2 md:divide-x md:divide-y-0 md:[&>*]:px-8',
          className,
        )}
        {...delegated}
      />
    );
  },
);
NCCard2SectionLayout.displayName = 'NCCard2SectionLayout';

type NCCardSectionHeaderProps = {
  title: React.ReactNode;
  supplementaryInfo?: React.ReactNode;
  popover?: {
    title?: React.ReactNode;
    body?: React.ReactNode;
  };
};

export const NCCardSectionHeader: FC<NCCardSectionHeaderProps> = ({ title, supplementaryInfo, popover }) => {
  const isSmallScreen = useScreenSize() === 'small';

  return (
    <Stack direction='row' className='items-start justify-between gap-2'>
      <span className='typography-body1'>
        {title}
        {popover && !isSmallScreen && (
          <span className='mx-2 align-middle leading-[0.8]'>
            <InfoPopover position='right' title={popover.title} body={popover.body} />
          </span>
        )}
      </span>
      {supplementaryInfo && !isSmallScreen && (
        <span className='typography-caption mt-[3px] flex-1 whitespace-nowrap text-right text-text-secondary'>
          {supplementaryInfo}
        </span>
      )}
    </Stack>
  );
};

export type NCCardAnalysisTypeToggleProps = Omit<ToggleButtonGroupProps<NCCardAnalysisType>, 'value'> & {
  value?: NCCardAnalysisType;
};

export const NCCardAnalysisTypeToggle: FC<NCCardAnalysisTypeToggleProps> = ({
  onChange,
  value,
  className,
  ...delegated
}) => {
  const { t } = useTranslation();
  const { analysisType, setAnalysisType } = useNCCardContext();

  const handleChange: NCCardAnalysisTypeToggleProps['onChange'] = (newValue) => {
    onChange?.(newValue);
    setAnalysisType(newValue);
  };

  return (
    <ToggleButtonGroup
      size='small'
      value={value ?? analysisType}
      onChange={handleChange}
      className={className}
      {...delegated}
    >
      <ToggleButton value='total'>{t('global.ui.toggle.total')}</ToggleButton>
      <ToggleButton value='average'>{t('global.ui.toggle.perHa')}</ToggleButton>
    </ToggleButtonGroup>
  );
};

type NCCardComplianceFooterProps = HTMLAttributes<HTMLDivElement> & {
  logos: ComplianceFrameworkLogo[];
};

export const NCCardComplianceFooter: FC<NCCardComplianceFooterProps> = ({ logos, className, ...delegated }) => {
  const { t } = useTranslation();
  const isSmallScreen = useScreenSize() === 'small';

  return (
    <>
      <SmallScreenExplainerOutlet />
      <div
        data-testid='compliance-footer'
        className={cn(
          'flex flex-col items-center justify-between gap-3 p-6 pt-0 sm:pt-6 md:border-t md:border-divider lg:flex-row',
          className,
        )}
        {...delegated}
      >
        {!isSmallScreen && (
          <div className='flex w-full flex-col items-center gap-3 md:flex-row'>
            <span className='typography-body1 text-text-primary'>{t('global.nca.complianceLogos.logosLabel')}</span>
            <Stack direction='row' spacing={3} className='flex-wrap items-center justify-center'>
              {logos.map((logo) => (
                <ComplianceLogo key={logo} logo={logo} />
              ))}
            </Stack>
          </div>
        )}
        <FrameworksLink />
      </div>
    </>
  );
};

const FrameworksLink = () => {
  const { t } = useTranslation();
  const isSmallScreen = useScreenSize() === 'small';

  const { pathname } = useLocation();
  const membershipType = useMembershipType();
  const projectId = useProjectId();

  const path =
    membershipType === MembershipWithOrganizationTypeEnum.land_steward
      ? paths.landSteward.projectsComplianceFrameworks
      : paths.buyer.projectsComplianceFrameworks;

  const to = buildPath(path, { queryParams: projectId ? { projectId } : undefined });

  return (
    <ButtonLink
      to={to}
      state={{
        previousPath: pathname,
      }}
      variant='text'
      className='px-0 sm:px-6'
    >
      {isSmallScreen
        ? t('global.nca.complianceLogos.smallScreenButtonLabel')
        : t('global.nca.complianceLogos.buttonLabel')}
    </ButtonLink>
  );
};

const ComplianceLogo: FC<{ logo: ComplianceFrameworkLogo }> = ({ logo }) => {
  const getLogoDetails = () => {
    switch (logo) {
      case 'eudr':
        return {
          src: complianceEudrSrc,
          alt: 'EUDR',
          label: 'EUDR',
        };
      case 'greenhouse':
        return {
          src: complianceGreenhouseSrc,
          alt: 'Greenhouse Gas Protocol',
          label: (
            <span>
              Greenhouse <span className='whitespace-nowrap'>Gas Protocol</span>
            </span>
          ),
        };
      case 'sai':
        return {
          src: complianceSaiSrc,
          alt: 'SAI',
          label: 'SAI Platform',
        };
      case 'science':
        return {
          src: complianceSbtiSrc,
          alt: 'SBTi',
          label: (
            <span>
              <span className='whitespace-nowrap'>Science-Based</span> Targets
            </span>
          ),
        };
      case 'tnfd':
        return {
          src: complianceTnfdSrc,
          alt: 'Taskforce on Nature-related Financial Disclosures',
          label: (
            <span>
              <span className='whitespace-nowrap'>TNFD</span>
            </span>
          ),
        };
      case 'rac':
        return {
          src: complianceRainforestSrc,
          alt: 'Rainforest Alliance Certification',
          label: <span className='whitespace-nowrap'>RAINFOREST ALLIANCE CERTIFICATION</span>,
        };
      default:
        return exhaustivenessCheck(logo);
    }
  };

  const logoDetails = getLogoDetails();

  return (
    <Stack direction='row' spacing={1} className='items-center rounded-lg border border-divider px-3 py-2'>
      <Stack center className='aspect-square w-8'>
        <img src={logoDetails.src} alt={logoDetails.alt} className='w-full' />
      </Stack>
      <span className='typography-body2 flex max-w-min whitespace-break-spaces uppercase leading-none text-text-secondary'>
        {logoDetails.label}
      </span>
    </Stack>
  );
};

/**
 * Formats the latest date. Returns 'N/A' if an empty list is provided, or if
 * one of the dates is invalid.
 */
export const printLatestDate = (dates: unknown[]) => {
  return getLatestDate(dates.filter((d) => !!d))?.toLocaleDateString(i18next.language) ?? 'N/A';
};

export type ExplainerWellProps = {
  title?: React.ReactNode;
  body: React.ReactNode;
};

export const ExplainerWell = ({ title, body }: ExplainerWellProps) => {
  return (
    <Stack spacing={4} className='rounded-2xl bg-neutral-black-4 px-4 py-6'>
      {title && <span className='typography-body1Semibold font-medium'>{title}</span>}
      <span className='leading-normal text-text-secondary [&_p]:mb-4'>{body}</span>
    </Stack>
  );
};

export type LatestAnalysisDatestampProps = (
  | {
      date: DateLike;
      facts?: never;
    }
  | {
      facts: FactLike | FactLike[];
      date?: never;
    }
) & { label?: React.ReactNode };

export const useLatestAnalysisDatestamp = (props: LatestAnalysisDatestampProps) => {
  const { t } = useTranslation();

  const label = props.label ?? t('shared.ncaDetail.details.labels.latestAnalysis');

  let date: Date | null = null;

  if ('date' in props) {
    const dateLike = props.date;
    date = dateLike ? new Date(dateLike) : null;
  } else if ('facts' in props) {
    if (Array.isArray(props.facts)) {
      date = getLatestDate(props.facts.map((f) => f.measured_at)) ?? null;
    } else {
      const timeString = props.facts?.measured_at;
      date = timeString ? new Date(timeString) : null;
    }
  }

  const datestamp: string = date?.toLocaleDateString() ?? 'N/A';

  return `${label} ${datestamp}`;
};
