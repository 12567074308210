import { Loader, Stack } from 'component-library';
import { useTranslation } from 'react-i18next';

import { SuspenseBoundary } from '@/components';

import { LandMonitoringPageWrapper } from '../../components/LandMonitoring.page-wrapper';
import { PlotListDesktop } from '../../components/plot-list/PlotListDesktop';
import { PlotListTitle } from '../../components/plot-list/PlotListTitle';

export const DeforestationDetailPage = () => {
  const { t } = useTranslation();

  const plotListFilters = {
    is_deforested: true,
  };

  return (
    <LandMonitoringPageWrapper title={t('shared.ncaDetail.details.deforestation.deforestation')}>
      <SuspenseBoundary
        fallback={
          <Stack center className='h-[600px]'>
            <Loader />
          </Stack>
        }
      >
        <PlotListTitle title={t('shared.ncaDetail.details.deforestation.plotsAffectedByDeforestation')} />
        <PlotListDesktop plotFilters={plotListFilters} />
      </SuspenseBoundary>
    </LandMonitoringPageWrapper>
  );
};
