import { Stack } from 'component-library';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useMethodologyPageLink } from '@/pages/shared/projects/pages/project/pages/conservation/hooks/useMethodologyPageLink';

import {
  Explainer,
  ExplainerBody,
  ExplainerContent,
  ExplainerCopy,
  ExplainerTitle,
  ExplainerTrigger,
  MethodologyLink,
} from './Explainer';

export type EcologicalIntegrityExplainerProps = {
  trigger?: React.ReactNode;
};

export const EcologicalIntegrityExplainer = (props: EcologicalIntegrityExplainerProps) => {
  const { t } = useTranslation();
  const methodologyPageLink = useMethodologyPageLink();

  return (
    <Explainer>
      {props.trigger ?? (
        <ExplainerTrigger data-testid='ecological-integrity-explainer'>
          {t('global.howDoWeMeasureThis')}
        </ExplainerTrigger>
      )}
      <ExplainerContent>
        <ExplainerTitle>{t('shared.ncaDetail.ecologicalIntegrity.explainers.howDoWeMeasure.title')}</ExplainerTitle>
        <Stack spacing={8} className='items-start pb-6'>
          <ExplainerBody className='pb-0'>
            <ExplainerCopy
              body={
                <div className='columns-1 leading-[22px] text-text-secondary md:columns-2'>
                  <Trans
                    i18nKey='shared.ncaDetail.ecologicalIntegrity.explainers.howDoWeMeasure.body'
                    components={{
                      ol: <ol className='ml-6 list-decimal space-y-2.5' />,
                      li: <li />,
                    }}
                  />
                </div>
              }
            />
          </ExplainerBody>
          <MethodologyLink to={methodologyPageLink} />
        </Stack>
      </ExplainerContent>
    </Explainer>
  );
};
