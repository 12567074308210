import { FC } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';

import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { PlotStatusEnum } from '@/api/rest/resources/types/plot';
import { useMembershipType } from '@/hooks/useMembershipType';
import { usePlot } from '@/pages/shared/hooks/usePlot';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { UnexpectedMissingDataError } from '@/utils/errors/UnexpectedMissingDataError';

import { withErrorBoundary } from '../components/ErrorBoundary';

export const RequireAccessiblePlot: FC = withErrorBoundary(() => {
  const { projectId } = useParams();
  const { plotId } = useParams();
  const membershipType = useMembershipType();

  if (!plotId) {
    throw new UnexpectedMissingDataError({ dataLabel: 'plotId' });
  }

  if (!projectId) {
    throw new UnexpectedMissingDataError({ dataLabel: 'projectId' });
  }

  const isUserLandSteward = membershipType === MembershipWithOrganizationTypeEnum.land_steward;
  const plot = usePlot().data;

  if (projectId !== plot.project) {
    throw new Error('Invalid Project Id');
  }

  if (plot.status === PlotStatusEnum.invalid) {
    const path = isUserLandSteward ? paths.landSteward.plots : paths.buyer.plots;

    return <Navigate to={buildPath(path, { pathParams: { projectId: plot.project } })} replace />;
  }

  return <Outlet />;
});
