import { ButtonLink } from '@landler/component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FullScreenError } from '@/components';
import { useContactFormLink } from '@/hooks/useContactFormLink';
import { paths } from '@/routing';

export const InvalidUrlError: React.FC = () => {
  const { t } = useTranslation();
  const contactFormUrl = useContactFormLink();

  return (
    <FullScreenError
      title={t('invalidUrlError.title')}
      body={t('invalidUrlError.description')}
      actions={
        <>
          <ButtonLink target='_blank' to={contactFormUrl} variant='outline'>
            {t('invalidUrlError.contactSupport')}
          </ButtonLink>
          <ButtonLink to={paths.root}>{t('invalidUrlError.backToHome')}</ButtonLink>
        </>
      }
    />
  );
};
