import { ButtonLink, RiArrowRightSLine, RiCompass4Line, Stack } from 'component-library';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Capsule, CapsuleContent, CapsuleIcon, CapsuleLabel } from '@/components';
import { ChartHeading } from '@/components/Charts/components/ChartHeading';
import {
  ExplainerWell,
  NCCard,
  NCCard2SectionLayout,
  NCCardHeader,
  NCCardSectionHeader,
} from '@/components/NCCard/NCCard';
import { FloraAndFaunaExplainer } from '@/components/NCExplainers/FloraAndFaunaExplainer';
import { usePathSet } from '@/hooks/usePathSet';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';
import { buildPath } from '@/utils/buildPath';

import { FactCapsule } from '../../../components/FactCapsule';
import { IndicatorSpeciesBreakdownChart } from '../../../components/IndicatorSpeciesBreakdownChart';
import { useFact } from '../../../hooks/useFact';
import { useLatestAnalysisDatestamp } from '../../../hooks/useLatestAnalysisDatestamp';

export const FloraAndFaunaCard = () => {
  const { t } = useTranslation();
  return (
    <NCCard explainer={<FloraAndFaunaExplainer />}>
      <NCCardHeader title={t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.title')} />

      <NCCard2SectionLayout className='[&>*]:py-8'>
        <GraphTile />
        <StatsTile />
      </NCCard2SectionLayout>
    </NCCard>
  );
};

const GraphTile = () => {
  const { t } = useTranslation();

  return (
    <Stack center spacing={6} className='z-[1]'>
      <ChartHeading
        heading={t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.chartHeading')}
        className='w-full px-6 sm:px-0'
      />
      <IndicatorSpeciesBreakdownChart />
    </Stack>
  );
};

const StatsTile = () => {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const pathSet = usePathSet();

  const projectId = useProjectId();

  const currentYear = useFact('r1_conservation_current_year');
  const detectedSpeciesCount = useFact('r1_conservation_groups_detected');
  const detectedSpeciesTotal = useFact('r1_conservation_groups_total');
  const average = useFact('r1_conservation_indicator_species_score');

  const latestAnalysisDatestamp = useLatestAnalysisDatestamp();

  return (
    <Stack spacing={10} className='px-6'>
      <Stack spacing={6}>
        <NCCardSectionHeader
          title={t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.statsHeading')}
          popover={{
            title: t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.explainers.floraAndFauna.title'),
            body: t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.explainers.floraAndFauna.body'),
          }}
          supplementaryInfo={latestAnalysisDatestamp}
        />
        <Stack spacing={16} className='flex-wrap md:flex-row'>
          <Capsule
            label={
              <CapsuleLabel
                infoPopoverProps={{
                  title: t(
                    'shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.explainers.indicatorGroupsDetected.title',
                  ),
                  body: t(
                    'shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.explainers.indicatorGroupsDetected.body',
                  ),
                }}
              >
                {t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.indicatorGroupsDetected')}
              </CapsuleLabel>
            }
            content={
              <CapsuleContent>
                {t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.detectedSpeciesDisplay', {
                  detectedCount: detectedSpeciesCount.value,
                  totalCount: detectedSpeciesTotal.value,
                })}
              </CapsuleContent>
            }
            thumbnail={<CapsuleIcon icon={<RiCompass4Line />} variant='default' />}
          />
          <FactCapsule
            label={t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.avgValue', {
              year: currentYear.value,
            })}
            fact={average}
            capsuleIconProps={{ icon: <RiCompass4Line />, variant: 'default' }}
            infoPopoverProps={{
              title: t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.explainers.averageValue.title', {
                year: currentYear.value,
              }),
              body: t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.explainers.averageValue.body', {
                year: currentYear.value,
              }),
            }}
          />
        </Stack>
      </Stack>

      <ExplainerWell
        title={t('global.whatDoesThisMean')}
        body={
          <Stack spacing={8}>
            <span>
              <Trans
                i18nKey='shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.explanation'
                values={{ detectedCount: detectedSpeciesCount.value, totalCount: detectedSpeciesTotal.value }}
              />
            </span>
            <ButtonLink
              to={buildPath(pathSet.floraAndFauna, { pathParams: { projectId } })}
              state={{ from: pathname }}
              variant='outline'
              className='md:self-start'
              rightAdornment={<RiArrowRightSLine size={20} />}
            >
              {t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.seeMoreDetails')}
            </ButtonLink>
          </Stack>
        }
      />
    </Stack>
  );
};
