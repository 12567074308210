import { IconKey } from '@landler/deprecated-component-library';
import { ReactNode } from 'react';

import { PlotReportFactElement, R1FactType } from '@/api/rest/resources/types/fact';
import { withSuspenseBoundary } from '@/components';
import { PendingPill } from '@/components/Pill/PendingPill';

import { DataLabel, DataStack } from './DataComponents';
import { FactPill } from './FactPill';

export const FactCell = withSuspenseBoundary(
  ({
    label,
    projectId,
    factType,
    iconName,
    formatter,
  }: {
    label: ReactNode;
    projectId: string;
    factType: R1FactType;
    iconName: IconKey;
    formatter?: (fact?: PlotReportFactElement) => PlotReportFactElement | undefined;
  }) => {
    return (
      <DataStack className='h-12'>
        <DataLabel>{label}</DataLabel>
        <FactPill projectId={projectId} factType={factType} iconName={iconName} formatter={formatter} />
      </DataStack>
    );
  },
  <PendingPill />,
);
