/* eslint-disable sonarjs/no-duplicate-string */
type ColorIndex = Array<{
  category: string;
  colors: Record<string, string>;
}>;

export const colorIndex: ColorIndex = [
  {
    category: 'Utility',
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
    },
  },

  {
    category: 'Primary',
    colors: {
      'primary-100': 'rgba(0, 76, 61, 1)',
      'primary-12': 'rgba(0, 76, 61, 0.12)',
      'primary-50': 'rgba(0, 76, 61, 0.5)',
      'primary-30': 'rgba(0, 76, 61, 0.30)',
      'primary-light': 'rgba(39, 134, 104, 1)',
    },
  },

  {
    category: 'Secondary',
    colors: {
      'secondary-100': 'rgba(51, 142, 90, 1)',
      'secondary-50': 'rgba(51, 142, 90, 0.5)',
      'secondary-30': 'rgba(51, 142, 90, 0.30)',
      'secondary-12': 'rgba(51, 142, 90, 0.12)',
    },
  },

  {
    category: 'Text',
    colors: {
      'text-primary': 'rgba(0, 0, 0, 0.87)',
      'text-secondary': 'rgba(0, 0, 0, 0.60)',
      'text-disabled': 'rgba(0, 0, 0, 0.38)',
    },
  },

  {
    category: 'Success',
    colors: {
      success: 'rgba(68, 187, 74, 1)',
      'success-dark': 'rgba(40, 94, 43, 1)',
      'success-light': 'rgba(207, 255, 191, 1)',
    },
  },

  {
    category: 'Warning',
    colors: {
      warning: 'rgba(255, 170, 30, 1)',
      'warning-dark': 'rgba(119, 66, 0, 1)',
      'warning-light': 'rgba(255, 242, 210, 1)',
    },
  },

  {
    category: 'Error',
    colors: {
      error: 'rgba(216, 59, 9, 1)',
      'error-dark': 'rgba(123, 41, 15, 1)',
      'error-light': 'rgba(246, 212, 193, 1)',
      'error-plot-background': 'rgba(216, 59, 9, 0.08)',
    },
  },

  {
    category: 'Info',
    colors: {
      info: 'rgba(0, 76, 61, 1)',
      'info-dark': 'rgba(0, 41, 33, 1)',
      'info-light': 'rgba(210, 227, 216, 1)',
    },
  },

  {
    category: 'Neutral',
    colors: {
      'neutral-black-80': 'rgba(0, 0, 0, 0.8)',
      'neutral-black-60': 'rgba(0, 0, 0, 0.60)',
      'neutral-black-30': 'rgba(0, 0, 0, 0.30)',
      'neutral-black-12': 'rgba(0, 0, 0, 0.12)',
      'neutral-black-10': 'rgba(0, 0, 0, 0.10)',
      'neutral-black-8': 'rgba(0, 0, 0, 0.08)',
      'neutral-black-4': 'rgba(0, 0, 0, 0.04)',
      'neutral-white-50': 'rgba(255, 255, 255, 0.5)',
      'neutral-white-30': 'rgba(255, 255, 255, 0.30)',
      'neutral-white-12': 'rgba(255, 255, 255, 0.12)',
      'neutral-white-8': 'rgba(255, 255, 255, 0.08)',
    },
  },

  {
    category: 'Background',
    colors: {
      'bg-light-grey': 'rgba(245, 245, 243, 1)',
      beige: 'rgba(243, 237, 211, 1)',
    },
  },

  {
    category: 'Other/Misc',
    colors: {
      'primary-hover': 'rgba(0, 41, 33, 1)',
      'secondary-hover': 'rgba(29, 94, 57, 1)',
      'neutral-hover': 'rgba(0, 76, 61, 0.12)',
      divider: 'rgba(0, 0, 0, 0.12)',
      'outlined-border-23p': 'rgba(0, 0, 0, 0.23)',
      'standard-input-line': 'rgba(0, 0, 0, 0.42)',
      'backdrop-overlay': 'rgba(0, 0, 0, 0.5)',
      'snackbar-bg': 'rgba(50, 50, 50, 1)',
      'disabled-button-bg': 'rgba(0, 0, 0, 0.12)',
      'graph-point': 'rgba(178, 178, 178, 1)',
      'graph-tooltip': 'rgba(224, 224, 224, 1)',
      'active-54': 'rgba(0, 0, 0, 0.54)',
      'new-plot': 'rgba(9, 141, 216, 1)',
      'new-plot-light': 'rgba(228, 240, 255, 1)',
      'new-plot-dark': 'rgba(20, 73, 102, 1)',
      'list-header': 'rgba(240, 240, 240, 1)',
    },
  },

  {
    category: 'Black, White',
    colors: {
      'black-100': 'rgba(0, 0, 0, 1)',
      'white-100': 'rgba(255, 255, 255, 1)',
    },
  },

  {
    category: 'Natural Capital',
    colors: {
      'natural-capital-co2': 'rgba(255, 221, 95, 1)',
      'natural-capital-co2-dark': 'rgba(128, 110, 51, 1)',
      'natural-capital-co2-light': 'rgba(255, 239, 178, 1)',
      'natural-capital-soil': 'rgba(247, 151, 79, 1)',
      'natural-capital-soil-light': 'rgba(255, 208, 172, 1)',
      'natural-capital-soil-dark': 'rgba(140, 64, 7, 1)',
      'natural-capital-water': 'rgba(106, 205, 225, 1)',
      'natural-capital-water-dark': 'rgba(27, 110, 128, 1)',
      'natural-capital-water-light': 'rgba(133, 218, 255, 1)',
      'natural-capital-biodiversity': 'rgba(194, 249, 109, 1)',
      'natural-capital-biodiversity-light': 'rgba(225, 255, 178, 1)',
      'natural-capital-biodiversity-dark': 'rgba(69, 110, 6, 1)',
    },
  },

  {
    category: 'Landtypes',
    colors: {
      'landtypes-forestry-main': 'rgba(64, 191, 148, 1)',
      'landtypes-forestry-light': 'rgba(140, 217, 191, 1)',
      'landtypes-forestry-dark': 'rgba(25, 128, 91, 1)',
      'landtypes-grassland-main': 'rgba(139, 210, 121, 1)',
      'landtypes-grassland-light': 'rgba(172, 223, 159, 1)',
      'landtypes-grassland-dark': 'rgba(76, 178, 50, 1)',
      'landtypes-cropland-main': 'rgba(242, 176, 48, 1)',
      'landtypes-cropland-light': 'rgba(255, 203, 102, 1)',
      'landtypes-cropland-dark': 'rgba(204, 135, 0, 1)',
      'landtypes-peatland-main': 'rgba(186, 145, 187, 1)',
      'landtypes-peatland-light': 'rgba(205, 176, 206, 1)',
      'landtypes-peatland-dark': 'rgba(139, 87, 140, 1)',
      'landtypes-shrubland-main': 'rgba(169, 189, 137, 1)',
      'landtypes-shrubland-light': 'rgba(205, 254, 124, 1)',
      'landtypes-shrubland-dark': 'rgba(91, 102, 74, 1)',
      'landtypes-sparse-vegetation-main': 'rgba(171, 138, 114, 1)',
      'landtypes-sparse-vegetation-light': 'rgba(255, 229, 211, 1)',
      'landtypes-sparse-vegetation-dark': 'rgba(102, 82, 68, 1)',
      'landtypes-mangroves-main': 'rgba(155, 168, 8, 1)',
      'landtypes-mangroves-light': 'rgba(209, 227, 4, 1)',
      'landtypes-mangroves-dark': 'rgba(102, 98, 0, 1)',
      'landtypes-snow-and-ice-main': 'rgba(156, 224, 240, 1)',
      'landtypes-snow-and-ice-light': 'rgba(204, 245, 255, 1)',
      'landtypes-snow-and-ice-dark': 'rgba(0, 165, 204, 1)',
      'landtypes-moss-and-lichens-main': 'rgba(166, 129, 0, 1)',
      'landtypes-moss-and-lichens-light': 'rgba(237, 200, 4, 1)',
      'landtypes-moss-and-lichens-dark': 'rgba(102, 86, 3, 1)',
      'landtypes-permanent-water-bodies-main': 'rgba(52, 164, 226, 1)',
      'landtypes-permanent-water-bodies-light': 'rgba(133, 218, 255, 1)',
      'landtypes-permanent-water-bodies-dark': 'rgba(30, 87, 120, 1)',
      'landtypes-built-up-environment-main': 'rgba(175, 172, 165, 1)',
      'landtypes-built-up-environment-light': 'rgba(233, 229, 221, 1)',
      'landtypes-built-up-environment-dark': 'rgba(102, 100, 96, 1)',
      'landtypes-misc-light': 'rgba(245, 245, 245, 1)',
      'landtypes-misc-main': 'rgba(189, 189, 189, 1)',
      'landtypes-misc-dark': 'rgba(117, 117, 117, 1)',
    },
  },
];

export const colorPalette = colorIndex.reduce((acc, { colors }) => ({ ...acc, ...colors }), {}) as Record<
  string,
  string
>;
