import DOMPurify from 'dompurify';

const config = {
  ALLOWED_TAGS: [],
};

export const hasHTMLContent = (value: string) => {
  const sanitizedValue = DOMPurify.sanitize(value, config);
  return sanitizedValue !== value;
};
