import { cn } from 'component-library';
import { FC, HTMLProps } from 'react';

const DataLabel: FC<HTMLProps<HTMLSpanElement>> = (delegated) => (
  <span
    className={cn('typography-overline block truncate pb-2.5 leading-4 text-text-disabled', delegated.className)}
    title={typeof delegated.children === 'string' ? delegated.children : undefined}
    {...delegated}
  />
);

const DataText: FC<HTMLProps<HTMLSpanElement>> = (delegated) => (
  <span
    className={cn('typography-h4 truncate', delegated.className)}
    title={typeof delegated.children === 'string' ? delegated.children : undefined}
    {...delegated}
  />
);

const DataStack: FC<HTMLProps<HTMLDivElement>> = (delegated) => (
  <div className={cn('truncate', delegated.className)} {...delegated} />
);

export { DataLabel, DataStack, DataText };
