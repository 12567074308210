import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  RowSelectionState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import {
  AppBar,
  AppBarContent,
  Button,
  cn,
  Container,
  Loader,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  toast,
  toastifyToast,
} from 'component-library';
import { Pagination } from 'component-library/Pagination/Pagination';
import { FC, HTMLProps, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { RestApiBadRequestServerError } from '@/api/rest/resources/errors/RestApiBadRequestError';
import { DraftPlot } from '@/api/rest/resources/types/plot';
import { withSuspenseBoundary } from '@/components';
import { useScreenSize } from '@/hooks/useScreenSize';
import { Logger } from '@/lib/logs/logger';
import { AreaCell, NameCell } from '@/pages/landsteward/pages/plot/pages/new/components/Cells';
import { ToggleSortButton } from '@/pages/landsteward/pages/plot/pages/new/components/ToggleSortButton';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { UnexpectedMissingDataError } from '@/utils/errors/UnexpectedMissingDataError';

import { usePlotForm } from '../../../hooks/usePlotForm';
import { TitleBar } from '../components/TitleBar';
import { useCommitDraftPlot } from '../hooks/useCommitDraftPlot';
import { useDraftPlot } from '../hooks/useDraftPlot';

const PAGE_SIZE = 5;

export const ReviewUploadPage = withSuspenseBoundary(
  () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { projectId } = useParams();
    const isSmallScreen = useScreenSize() === 'small';

    const form = usePlotForm();
    const selectedPlotType = form.watch('plotType');
    if (!projectId) throw new UnexpectedMissingDataError({ dataLabel: 'projectId' });

    if (!selectedPlotType) throw new UnexpectedMissingDataError({ dataLabel: 'plotType' });

    const { count: totalPlotCount } = useDraftPlot(selectedPlotType, 0, 0).data; // first empty query to fetch total count
    const { results: plots } = useDraftPlot(selectedPlotType, totalPlotCount, 0).data; // second query to fetch all plots using total count

    const { submit, isSubmitting } = useCommitDraftPlot();

    const commitDraftPlot = async () => {
      try {
        toastifyToast.clearWaitingQueue();
        toastifyToast.dismiss();
        const commitDraftPlots: string[] = (Object.keys(rowSelection) as string[]).map((index) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return plots[Number(index)]!.id;
        });
        await submit({ draftPlots: commitDraftPlots });
        navigate(buildPath(paths.landSteward.newPlotSuccess, { pathParams: { projectId } }));
      } catch (error) {
        let description = t('landSteward.plot.new.reviewPage.submitFailedErrorDescription');
        if (error instanceof RestApiBadRequestServerError && error.errors[0]) {
          [description] = error.errors;
        }

        Logger.errorOnce(error as Error);

        toast({
          title: t('landSteward.plot.createFailedErrorTitle'),
          description,
          type: 'error',
          autoClose: 10_000,
        });
      }
    };

    const columns = useMemo<ColumnDef<DraftPlot>[]>(
      () => [
        {
          id: 'select',
          header: ({ table }) => (
            <Checkbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          ),
          cell: ({ row }) => (
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          ),
        },
        {
          accessorKey: 'name',
          header: ({ column }) => (
            <ToggleSortButton column={column} className='-ml-2.5'>
              {t('landSteward.projects.plots.tableHeader.name')}
            </ToggleSortButton>
          ),
          cell: NameCell,
        },
        {
          accessorKey: 'area',
          header: ({ column }) => (
            <ToggleSortButton column={column} className='-ml-2.5'>
              {t('landSteward.projects.plots.tableHeader.size')}
            </ToggleSortButton>
          ),
          cell: AreaCell,
        },
      ],
      [t],
    );

    // @ts-expect-error: accessorKey is available, but the ColumnDef type does not include it.
    const [sorting, setSorting] = useState<SortingState>([{ id: columns[1]?.accessorKey, desc: false }]);
    // all rows are selected by default
    const [rowSelection, setRowSelection] = useState<RowSelectionState>(
      Object.fromEntries(Array.from(Array(plots.length).keys()).map((index) => [index.toString(), true])),
    );
    const table = useReactTable({
      data: plots,
      columns,
      getCoreRowModel: getCoreRowModel(),
      onSortingChange: setSorting,
      getSortedRowModel: getSortedRowModel(),
      initialState: {
        pagination: {
          pageIndex: 0,
          pageSize: PAGE_SIZE,
        },
      },
      state: {
        sorting,
        rowSelection,
      },
      enableRowSelection: true,
      onRowSelectionChange: setRowSelection,
      getPaginationRowModel: getPaginationRowModel(),
    });

    const [currentPage, setCurrentPage] = useState(1);

    const pageStart = table.getState().pagination.pageIndex * PAGE_SIZE;
    const pageEnd = pageStart + PAGE_SIZE;

    return (
      <Stack className='min-h-screen items-center' data-testid='review-upload-page'>
        <TitleBar currentStep={3} />
        <Container contentWidth='md' className='flex-1 py-10 [text-wrap:_balance]'>
          <Stack spacing={8}>
            <Stack spacing={4} className='mb-10'>
              <h1 className='typography-display2 text-center text-primary-100' data-cy='review-plots-title'>
                {t('landSteward.plot.new.reviewPage.title', { count: plots.length })}
              </h1>
              <span className='whitespace-pre-line text-center' data-cy='review-plot-desc'>
                {t('landSteward.plot.new.reviewPage.subtitle')}
              </span>
            </Stack>
            <Stack className='flex flex-col gap-4'>
              <Stack className='flex flex-row items-center justify-between gap-2'>
                <span className='typography-overline' data-cy='showing-plot-count'>
                  {t('landSteward.plot.new.reviewPage.pagination.title', {
                    count: plots.length,
                    start: Math.max(1, pageStart + 1), // start at 1 for display (pageStart is 0-indexed)
                    end: Math.min(pageEnd, plots.length), // show total plots if pageEnd overshoots in the end
                  })}
                </span>
                {!isSmallScreen && (
                  <Pagination
                    className='typography-overline text-text-secondary'
                    currentPage={currentPage}
                    totalCount={plots.length}
                    pageSize={PAGE_SIZE}
                    onPageChange={(nextPage) => {
                      table.setPageIndex(nextPage - 1);
                      setCurrentPage(nextPage);
                    }}
                  />
                )}
              </Stack>
              <div className='rounded-3xl bg-white-100'>
                <Table data-testid='plots-table' data-cy='plots-table'>
                  <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <TableRow key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <TableHead key={header.id} data-cy={`${header.id}plots-table-head`}>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </TableHead>
                        ))}
                      </TableRow>
                    ))}
                  </TableHeader>
                  <TableBody>
                    {table.getRowModel().rows.map((row) => (
                      <TableRow
                        data-testid='plot-row'
                        key={row.id}
                        data-cy={`${row.id}-plot-row`}
                        className='cursor-pointer'
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell data-cy={`cell_${cell.id}`} key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              {isSmallScreen && (
                <Stack className='flex items-center'>
                  <Pagination
                    className='typography-overline text-text-secondary'
                    currentPage={currentPage}
                    totalCount={plots.length}
                    pageSize={PAGE_SIZE}
                    onPageChange={(nextPage) => {
                      table.setPageIndex(nextPage - 1);
                      setCurrentPage(nextPage);
                    }}
                  />
                </Stack>
              )}
            </Stack>
          </Stack>
        </Container>
        <AppBar placement='bottom'>
          <AppBarContent>
            <Stack direction='row' className='w-full flex-1 justify-between'>
              <Button onClick={() => navigate(-1)} variant='outline'>
                {t('global.ui.buttons.back')}
              </Button>
              <Button
                type='submit'
                onClick={commitDraftPlot}
                loading={isSubmitting}
                disabled={Object.keys(rowSelection).length < 1} // disable if no plots are selected
                data-cy='import-plots-button'
              >
                {t('landSteward.plot.new.reviewPage.submit')}
              </Button>
            </Stack>
          </AppBarContent>
        </AppBar>
      </Stack>
    );
  },
  <Loader className='min-h-[300px]' />,
);

type CheckboxProps = HTMLProps<HTMLInputElement> & { indeterminate: boolean };
const Checkbox: FC<CheckboxProps> = ({ indeterminate, className = '', ...delegated }) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current && typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !delegated.checked && indeterminate;
    }
  }, [ref, indeterminate, delegated.checked]);

  return (
    <input
      type='checkbox'
      ref={ref}
      className={cn('w-6 cursor-pointer checked:accent-secondary-100', className)}
      {...delegated}
    />
  );
};
