import { Stack } from 'component-library';
import { Trans, useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { CapsuleTrend } from '@/components';
import { NCCardSectionHeader, printLatestDate, useNCCardContext } from '@/components/NCCard/NCCard';
import {
  getNCChangeIcon,
  getNCChangeIconVariant,
  NCFactCapsule,
  NCFactCapsuleProps,
} from '@/components/NCFactCapsule/NCFactCapsule';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { useFact } from '@/hooks/useFact';
import { useProjectFact } from '@/pages/shared/hooks/useProjectFact';
import { formatFactKilogramsToTonnes } from '@/utils/formatting/formatKgToTon';

export const AboveCarbonCardStatsTile = () => {
  return (
    <Stack className='gap-8 px-6 py-8 lg:gap-4' data-testid='carbon-stats-tile'>
      <AboveGroundCarbonStorage />
    </Stack>
  );
};

// TODO: MVP-4772 - change with forest fact
// this includes all the other places where the BG carbon facts are used in this file

const AboveGroundCarbonStorage = () => {
  const { t } = useTranslation();

  const { analysisType } = useNCCardContext();

  const carbonAgLatestAnalysis = formatFactKilogramsToTonnes(
    useProjectFact(
      analysisType === 'total'
        ? R1FactType.r1_carbon_bg_latest_analysis_total
        : R1FactType.r1_carbon_bg_latest_analysis_per_ha,
    ),
  );

  const carbonAg1YearTrend = useProjectFact<number>(
    analysisType === 'total'
      ? R1FactType.r1_carbon_bg_previous_1y_uplift_total_pct_change
      : R1FactType.r1_carbon_bg_previous_1y_uplift_per_ha_pct_change,
  );
  let carbonAg1YearTrendDisplay = `${useDisplayNumber(carbonAg1YearTrend?.value ?? 0, { signDisplay: 'always' })} %`;
  carbonAg1YearTrendDisplay = carbonAg1YearTrend?.value != null ? carbonAg1YearTrendDisplay : '-';

  const carbonAg1yrChange = formatFactKilogramsToTonnes(
    useProjectFact(
      analysisType === 'total'
        ? R1FactType.r1_carbon_bg_previous_1y_uplift_total
        : R1FactType.r1_carbon_bg_previous_1y_uplift_per_ha,
    ),
  );

  const equivalentExplainer = useEquivalentsExplainer();

  const latestAnalysisDate = printLatestDate([carbonAgLatestAnalysis?.measured_at]);

  const latestAnalysisBody = t('shared.ncaDetail.details.explainers.latestAnalysis.body', {
    date: latestAnalysisDate,
  });

  const popoverCarbonAgLatestAnalysis: NCFactCapsuleProps['infoPopoverProps'] = equivalentExplainer
    ? {
        title: t('shared.ncaDetail.details.explainers.latestAnalysis.title'),
        body: `${latestAnalysisBody}\n${equivalentExplainer}`,
        popoverTriggerProps: {
          'data-testid': 'carbon-ag-latest-analysis-popover',
        },
      }
    : undefined;

  const carbonAg1YrChangeInitialMeasuredAt = printLatestDate([carbonAgLatestAnalysis?.measured_at]);
  const carbonAg1YrChangePreviousMeasuredAt = printLatestDate([carbonAg1yrChange?.measured_at]);

  return (
    <div className='flex flex-col gap-8 lg:gap-4'>
      <NCCardSectionHeader
        title={t('shared.ncaDetail.details.aboveGroundCarbon.labels.aboveGroundCarbonStorage')}
        supplementaryInfo={`${t('shared.ncaDetail.details.labels.updated')} ${latestAnalysisDate}`}
        popover={{
          title: t('shared.ncaDetail.details.aboveGroundCarbon.explainers.aboveGroundCarbonStorage.title'),
          body: t('shared.ncaDetail.details.aboveGroundCarbon.explainers.aboveGroundCarbonStorage.body'),
        }}
      />
      <Stack direction='row' className='flex-wrap gap-16'>
        <NCFactCapsule
          label={t('shared.ncaDetail.details.labels.latestAnalysis')}
          fact={carbonAgLatestAnalysis}
          trend={
            typeof carbonAg1YearTrend?.value === 'number' && (
              <CapsuleTrend variant={carbonAg1YearTrend.value < 0 ? 'negative' : 'positive'}>
                {carbonAg1YearTrendDisplay}
              </CapsuleTrend>
            )
          }
          capsuleIconProps={{
            icon: getNCChangeIcon(carbonAgLatestAnalysis),
            variant: getNCChangeIconVariant(carbonAgLatestAnalysis),
          }}
          infoPopoverProps={popoverCarbonAgLatestAnalysis}
        />
        {!!carbonAg1yrChange?.value && (
          <NCFactCapsule
            label={t('shared.ncaDetail.details.labels.oneYearChange')}
            fact={carbonAg1yrChange}
            capsuleIconProps={{
              icon: getNCChangeIcon(carbonAg1yrChange),
              variant: getNCChangeIconVariant(carbonAg1yrChange),
            }}
            infoPopoverProps={{
              title: t('shared.ncaDetail.details.aboveGroundCarbon.explainers.oneYearChange.title'),
              body: t('shared.ncaDetail.details.aboveGroundCarbon.explainers.oneYearChange.body', {
                date: carbonAg1YrChangeInitialMeasuredAt,
                previousDate: carbonAg1YrChangePreviousMeasuredAt,
              }),
              popoverTriggerProps: {
                'data-testid': 'carbon-1yr-change-tooltip',
              },
            }}
          />
        )}
      </Stack>
      <Stack className='mt-4 gap-4 rounded-2xl bg-neutral-black-4 px-4 py-6'>
        <span className='typography-h4'>
          {t('shared.ncaDetail.details.aboveGroundCarbon.explainers.aboveGroundCarbonStorageDetails.title')}
        </span>
        <Trans
          i18nKey='shared.ncaDetail.details.aboveGroundCarbon.explainers.aboveGroundCarbonStorageDetails.body'
          values={{
            trendPercent: carbonAg1YearTrendDisplay,
          }}
          components={{
            p: <p className='typography-body1 text-text-secondary' />,
          }}
        />
      </Stack>
    </div>
  );
};

const useEquivalentsExplainer = () => {
  // TODO: MVP-4772 check if AGC models provide carbon equivalent facts
  const { t } = useTranslation();

  const { analysisType } = useNCCardContext();

  const carbonStorageAg = formatFactKilogramsToTonnes(
    useProjectFact(
      analysisType === 'total' ? R1FactType.r1_carbon_storage_bg_total : R1FactType.r1_carbon_storage_bg_per_ha,
    ),
  );
  const carbonStorageAgCo2Equivalent = formatFactKilogramsToTonnes(
    useProjectFact(
      analysisType === 'total'
        ? R1FactType.r1_carbon_storage_bg_co2_equivalent_total
        : R1FactType.r1_carbon_storage_bg_co2_equivalent_per_ha,
    ),
  );

  const { display: carbonStorageAgDisplay, value: carbonStorageAgValue } = useFact(carbonStorageAg);

  const { display: carbonStorageAgCo2EquivalentDisplay, value: carbonStorageAgCo2EquivalentValue } =
    useFact(carbonStorageAgCo2Equivalent);

  if (!carbonStorageAgValue || !carbonStorageAgCo2EquivalentValue) return undefined;

  return t('shared.ncaDetail.details.carbon.explainers.equivalents', {
    cValue: carbonStorageAgDisplay,
    co2Value: carbonStorageAgCo2EquivalentDisplay,
  });
};
