import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { RoleEnum } from '@/api/rest/resources/types/membership';
import { useAccountPageRoutes } from '@/hooks/useAccountPageRoutes';
import { useUser } from '@/lib/auth';

export const RequireAdminRole: FC = () => {
  const user = useUser();
  const { restrictedAccess } = useAccountPageRoutes();

  if (user.membership.role !== RoleEnum.admin) {
    return <Navigate to={restrictedAccess} replace />;
  }

  return <Outlet />;
};
