import { Stack } from 'component-library';
import { useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { NCCardSectionHeader, printLatestDate } from '@/components/NCCard/NCCard';
import { getNCChangeIcon, getNCChangeIconVariant, NCFactCapsule } from '@/components/NCFactCapsule/NCFactCapsule';
import { usePlotFact } from '@/pages/shared/hooks/usePlotFact';

export const CanopyCoverCardStatsTile = () => {
  return (
    <Stack className='gap-8 px-6 py-8 lg:gap-4' data-testid='canopy-cover-stats-tile'>
      <AverageCanopyCover />
    </Stack>
  );
};

const AverageCanopyCover = () => {
  const { t } = useTranslation();

  const averageCanopyCoverLatestAnalysis = usePlotFact(R1FactType.r1_carbon_bg_previous_1y_uplift_total_pct_change); // TODO:MVP-4772 - change with forest fact

  const latestAnalysisDate = printLatestDate([averageCanopyCoverLatestAnalysis?.measured_at]);

  return (
    <div className='flex flex-col gap-8 lg:gap-4'>
      <NCCardSectionHeader
        title={t('shared.ncaDetail.details.forest.labels.averageCanopyCover')}
        supplementaryInfo={`${t('shared.ncaDetail.details.labels.updated')} ${latestAnalysisDate}`}
        popover={{
          title: t('shared.ncaDetail.details.forest.explainers.averageCanopyCover.title'),
          body: t('shared.ncaDetail.details.forest.explainers.averageCanopyCover.body'),
        }}
      />
      <Stack direction='row' className='flex-wrap gap-16'>
        <NCFactCapsule
          label={t('shared.ncaDetail.details.labels.latestAnalysis')}
          fact={averageCanopyCoverLatestAnalysis}
          capsuleIconProps={{
            icon: getNCChangeIcon(averageCanopyCoverLatestAnalysis),
            variant: getNCChangeIconVariant(averageCanopyCoverLatestAnalysis),
          }}
          infoPopoverProps={{
            title: t('shared.ncaDetail.details.explainers.latestAnalysis.title'),
            body: t('shared.ncaDetail.details.explainers.latestAnalysis.body', {
              date: latestAnalysisDate,
            }),
            popoverTriggerProps: {
              'data-testid': 'average-canopy-cover-latest-analysis-popover',
            },
          }}
        />
      </Stack>

      <Stack className='mt-4 gap-4 rounded-2xl bg-neutral-black-4 px-4 py-6'>
        <span className='typography-h4'>
          {t('shared.ncaDetail.details.forest.explainers.canopyCoverDetails.title')}
        </span>
        <p className='typography-body1 text-text-secondary'>
          {t('shared.ncaDetail.details.forest.explainers.canopyCoverDetails.body')}
        </p>
      </Stack>
    </div>
  );
};
