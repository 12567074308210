import { Stack } from 'component-library';
import { useTranslation } from 'react-i18next';

import { METHODOLOGY_EXPLAINER_PAGE_NCI } from '@/config/constants';

import {
  Explainer,
  ExplainerBody,
  ExplainerContent,
  ExplainerCopy,
  ExplainerTitle,
  ExplainerTrigger,
  MethodologyLink,
} from './Explainer';

export const CanopyHeightExplainer = () => {
  const { t } = useTranslation();

  return (
    <Explainer>
      <ExplainerTrigger data-testid='canopy-height-explainer'>
        {t('shared.ncaDetail.details.labels.howDoWeMeasureThis')}
      </ExplainerTrigger>
      <ExplainerContent>
        <ExplainerTitle>
          {t('shared.ncaDetail.details.forest.explainers.canopyHeight.howDoMeasureThis.headline')}
        </ExplainerTitle>
        <Stack spacing={8} className='items-start pb-6'>
          <ExplainerBody className='pb-0'>
            <ExplainerCopy
              body={t('shared.ncaDetail.details.forest.explainers.canopyHeight.howDoMeasureThis.canopyHeightBodyFirst')}
            />
            <ExplainerCopy
              body={t(
                'shared.ncaDetail.details.forest.explainers.canopyHeight.howDoMeasureThis.canopyHeightBodySecond',
              )}
            />
          </ExplainerBody>
          <MethodologyLink to={METHODOLOGY_EXPLAINER_PAGE_NCI} />
        </Stack>
      </ExplainerContent>
    </Explainer>
  );
};
