import { ReactComponent as arrowBack } from '@assets/images/icons/arrow-back.svg';
import { ReactComponent as biodiversity } from '@assets/images/icons/biodiversity.svg';
import { ReactComponent as checkmarkCircleFilled } from '@assets/images/icons/checkmark-circle-filled.svg';
import { ReactComponent as co2 } from '@assets/images/icons/co2.svg';
import { ReactComponent as expandMoreFilled } from '@assets/images/icons/expand-more-filled.svg';
import { ReactComponent as landtypeBareAndSparseVegetation } from '@assets/images/icons/landtype-bare-and-sparse-vegetation.svg';
import { ReactComponent as landtypeBuiltUpAreas } from '@assets/images/icons/landtype-built-up-areas.svg';
import { ReactComponent as landtypeCropland } from '@assets/images/icons/landtype-cropland.svg';
import { ReactComponent as landtypeForestry } from '@assets/images/icons/landtype-forestry.svg';
import { ReactComponent as landtypeGrassland } from '@assets/images/icons/landtype-grassland.svg';
import { ReactComponent as landtypeHerbaceousWetland } from '@assets/images/icons/landtype-herbaceous-wetland.svg';
import { ReactComponent as landtypeMangroves } from '@assets/images/icons/landtype-mangroves.svg';
import { ReactComponent as landtypeMossAndLichens } from '@assets/images/icons/landtype-moss-and-lichens.svg';
import { ReactComponent as landtypePeatland } from '@assets/images/icons/landtype-peatland.svg';
import { ReactComponent as landtypePermanentWaterBodies } from '@assets/images/icons/landtype-permanent-water-bodies.svg';
import { ReactComponent as landtypeShrubland } from '@assets/images/icons/landtype-shrubland.svg';
import { ReactComponent as landtypeSnowAndIce } from '@assets/images/icons/landtype-snow-and-ice.svg';
import { ReactComponent as landtypeTreeCover } from '@assets/images/icons/landtype-tree-cover.svg';
import { ReactComponent as locationFilled } from '@assets/images/icons/location-filled.svg';
import { ReactComponent as logoutFilled } from '@assets/images/icons/logout-filled.svg';
import { ReactComponent as magnifier } from '@assets/images/icons/magnifier.svg';
import { ReactComponent as menuFilled } from '@assets/images/icons/menu-filled.svg';
import { ReactComponent as pen } from '@assets/images/icons/pen.svg';
import { ReactComponent as person } from '@assets/images/icons/person.svg';
import { ReactComponent as personFilled } from '@assets/images/icons/person-filled.svg';
import { ReactComponent as plot } from '@assets/images/icons/plot.svg';
import { ReactComponent as plusFilled } from '@assets/images/icons/plus-filled.svg';
import { ReactComponent as science } from '@assets/images/icons/science.svg';
import { ReactComponent as trash } from '@assets/images/icons/trash.svg';
import { ReactComponent as triangleDown } from '@assets/images/icons/triangle-down.svg';
import { ReactComponent as visibilityOff } from '@assets/images/icons/visibility-off.svg';
import { ReactComponent as visibilityOn } from '@assets/images/icons/visibility-on.svg';
import { ReactComponent as waitclock } from '@assets/images/icons/waitclock.svg';
import { ReactComponent as water } from '@assets/images/icons/water.svg';
import { ReactComponent as world } from '@assets/images/icons/world.svg';

export const allIcons = {
  'arrow-back': arrowBack,
  biodiversity,
  'checkmark-circle-filled': checkmarkCircleFilled,
  co2,
  'expand-more-filled': expandMoreFilled,
  'landtype-bare-and-sparse-vegetation': landtypeBareAndSparseVegetation,
  'landtype-built-up-areas': landtypeBuiltUpAreas,
  'landtype-cropland': landtypeCropland,
  'landtype-forestry': landtypeForestry,
  'landtype-grassland': landtypeGrassland,
  'landtype-herbaceous-wetland': landtypeHerbaceousWetland,
  'landtype-mangroves': landtypeMangroves,
  'landtype-moss-and-lichens': landtypeMossAndLichens,
  'landtype-peatland': landtypePeatland,
  'landtype-permanent-water-bodies': landtypePermanentWaterBodies,
  'landtype-shrubland': landtypeShrubland,
  'landtype-snow-and-ice': landtypeSnowAndIce,
  'landtype-tree-cover': landtypeTreeCover,
  'location-filled': locationFilled,
  logoutFilled,
  magnifier,
  'menu-filled': menuFilled,
  pen,
  person,
  'person-filled': personFilled,
  plot,
  'plus-filled': plusFilled,
  science,
  trash,
  'triangle-down': triangleDown,
  'visibility-off': visibilityOff,
  'visibility-on': visibilityOn,
  waitclock,
  water,
  world,
};
