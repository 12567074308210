import { feature as turfFeature, featureCollection } from '@turf/helpers';
import { centerOfMass } from '@turf/turf';
import { Feature, Point } from 'geojson';
import { Point as MapboxPoint } from 'mapbox-gl';
import { useCallback, useMemo, useRef, useState } from 'react';
import {
  GeoJSONSource,
  Layer,
  LayerProps,
  LngLatBoundsLike,
  LngLatLike,
  MapLayerMouseEvent,
  MapRef,
  Source,
} from 'react-map-gl';

import { Plot, PlotType } from '@/api/rest/resources/types/plot';
import { Map } from '@/components/Map';
import { useBoundingBox } from '@/components/MapOverview/hooks/useBoundingBox';
import { MAP_MAX_ZOOM, MAP_OVERVIEW_PADDING_MOBILE, SHOW_APPROXIMATE_PLOTS_AS_PINS } from '@/config/constants';
import { useScreenSize } from '@/hooks/useScreenSize';
import { usePlotsForProject } from '@/pages/shared/hooks/usePlotsForProject';
import { getGeometriesForPlots } from '@/utils/bounds';
import { getPNGIconForLandType } from '@/utils/getPNGIconForLandType';
import { isTestEnv } from '@/utils/isTestEnv';
import { getColorOfPlotType } from '@/utils/plot';

import { ProjectMapPopup } from './ProjectMapPopup';

const POLYGONS_SOURCE_ID = 'plot-polygons';
const MARKERS_SOURCE_ID = 'plot-markers';

export const ProjectMap = () => {
  const isLargeScreen = useScreenSize() === 'large';

  const plots = usePlotsForProject().data.results;

  /**
   * The Map's onLoad callback is not triggered in the test environment, and so
   * the map remains hidden. We set the default value of isMapReady to true in
   * the test environment.
   */
  const [isMapReady, setIsMapReady] = useState(isTestEnv);
  const [selectedPlot, setSelectedPlot] = useState<Plot | null>(null);

  const geometries = useMemo(() => getGeometriesForPlots(plots), [plots]);
  const mapRef = useRef<MapRef | null>(null);

  const { bounds } = useBoundingBox({ geometries, padding: MAP_OVERVIEW_PADDING_MOBILE, mapRef: mapRef.current });

  const mapRefCallback = useCallback((ref: MapRef | null) => {
    if (ref !== null) {
      mapRef.current = ref;
      const map = ref;

      /**
       * Once the map is loaded, we load the icon PNGs so
       * that they can be referenced by the `iconLayer` layer.
       */
      Object.values(PlotType).forEach((plotType: PlotType) => {
        const loadImage = () => {
          if (!map.hasImage?.(`${plotType}-icon`)) {
            map.loadImage?.(getPNGIconForLandType(plotType), (error, image) => {
              if (error || image === undefined) throw error;
              map.addImage(`${plotType}-icon`, image, { sdf: true });
            });
          }
        };

        loadImage();
      });
    }
  }, []);

  /**
   * Explode the cluster and zoom in when a cluster is clicked.
   */
  const handleClusterMarkerClick = (feature: Feature<Point>, clusterId: number) => {
    if (!mapRef.current) return;

    const mapboxSource = mapRef.current.getSource(MARKERS_SOURCE_ID) as GeoJSONSource;

    mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) return;
      if (!mapRef.current) return;

      mapRef.current.easeTo({
        center: feature.geometry.coordinates as LngLatLike,
        zoom,
        duration: 500,
      });
    });
  };

  /**
   * Show a popup with plot details when a polygon or marker is clicked.
   */
  const handlePolygonOrUnclusteredMarkerClick = (feature: Feature) => {
    if (!isLargeScreen) return;

    const plot = JSON.parse(feature.properties?.plot) as Plot | undefined;

    if (!plot) return;

    /**
     * We do this song and dance so that the popup is first torn-down
     * and then created afresh. Without this, the popup does not update
     * when a different plot is clicked while the popup is open.
     */
    setSelectedPlot(null);
    setTimeout(() => {
      setSelectedPlot(plot);
    }, 0);

    const polygonCentroid = centerOfMass(plot.polygon).geometry.coordinates;

    mapRef.current?.easeTo({
      center: polygonCentroid as [number, number],
      duration: 500,
      offset: new MapboxPoint(-200, 0),
    });
  };

  /**
   * If a cluster is clicked, it explodes the cluster and zooms in.
   * If a polygon or marker is clicked, it shows the plot details popup.
   */
  const handleMapClick = (event: MapLayerMouseEvent) => {
    const feature = event.features?.[0];

    if (!feature) return;

    const clusterId = feature.properties?.cluster_id;

    if (clusterId) {
      handleClusterMarkerClick(feature as Feature<Point>, clusterId);
    } else {
      handlePolygonOrUnclusteredMarkerClick(feature);
    }
  };

  const hoveredPolygonIdRef = useRef<number | null>(null);

  /**
   * This handles two things:
   * 1. It sets the cursor to `pointer` when hovering over a plot
   * 2. It sets the opacity of the polygon when hovering over a plot
   *
   * They both indicate to the user that the plot is clickable.
   */
  const handleMapMouseMove = (event: MapLayerMouseEvent) => {
    const map = mapRef.current;

    if (!map) return;

    const feature = event.features?.[0];

    if (!feature) {
      map.getCanvas().style.cursor = 'initial';

      if (hoveredPolygonIdRef.current !== null) {
        map.setFeatureState(
          { source: POLYGONS_SOURCE_ID, id: hoveredPolygonIdRef.current as number },
          { hover: false },
        );
        hoveredPolygonIdRef.current = null;
      }
    } else {
      map.getCanvas().style.cursor = 'pointer';

      if (hoveredPolygonIdRef.current !== null) {
        map.setFeatureState(
          { source: POLYGONS_SOURCE_ID, id: hoveredPolygonIdRef.current as number },
          { hover: false },
        );
      }
      hoveredPolygonIdRef.current = feature.id as number;
      map.setFeatureState({ source: POLYGONS_SOURCE_ID, id: hoveredPolygonIdRef.current as number }, { hover: true });
    }
  };

  /**
   * We have separate collections for the points (plotMarkers) and polygons
   * (plotPolygons). We supply them to separate sources with IDs
   * POLYGONS_SOURCE_ID and MARKERS_SOURCE_ID.
   *
   * The `plotMarkers` are clustered using Mapbox's clustering feature, so that
   * the markers don't look crowded when there are several markers close
   * together.
   */
  const plotMarkers = featureCollection(
    plots.map((plot, index) => ({
      ...centerOfMass(plot.polygon, {
        properties: {
          plot,
          name: plot.name,
          landType: plot.type,
          icon: `${plot.type}-icon`,
        },
      }),
      /**
       * We use index instead of plot.id because Mapbox doesn't allow
       * alpha-numeric feature IDs. Also, 0 is not a valid feature ID.
       * https://github.com/mapbox/mapbox-gl-js/issues/7986#issuecomment-469381664
       */
      id: index + 1,
    })),
  );
  const plotPolygons = featureCollection(
    plots
      .filter((plot) => !(SHOW_APPROXIMATE_PLOTS_AS_PINS && plot.is_approximate))
      .map((plot, index) =>
        turfFeature(
          plot.polygon,
          { plot, color: getColorOfPlotType(plot.type) },
          /**
           * We use index instead of plot.id because Mapbox doesn't allow
           * alpha-numeric feature IDs. Also, 0 is not a valid feature ID.
           * https://github.com/mapbox/mapbox-gl-js/issues/7986#issuecomment-469381664
           */
          { id: index + 1 },
        ),
      ),
  );

  return (
    <div
      className='h-[187px] w-full overflow-hidden rounded-3xl bg-primary-50 sm:h-[494px] md:h-full'
      data-testid='project-map'
    >
      <Map
        ref={mapRefCallback}
        cooperativeGestures
        data-testid='project-map-gl'
        initialViewState={{
          bounds: bounds as LngLatBoundsLike,
          fitBoundsOptions: { padding: MAP_OVERVIEW_PADDING_MOBILE, maxZoom: MAP_MAX_ZOOM },
        }}
        onLoad={() => setIsMapReady(true)}
        interactiveLayerIds={[clusterLayer.id as string, unclusteredPointLayer.id as string, 'feat-fill']}
        onClick={handleMapClick}
        onMouseMove={handleMapMouseMove}
        style={{ opacity: isMapReady ? 1 : 0, transition: 'opacity 0.2s' }}
      >
        <Source id={POLYGONS_SOURCE_ID} type='geojson' data={plotPolygons}>
          <Layer {...polygonOutlineLayer} />
          <Layer {...polygonFillLayer} />
        </Source>
        <Source id={MARKERS_SOURCE_ID} type='geojson' data={plotMarkers} cluster={true}>
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...unclusteredPointLayer} />
          <Layer {...iconLayer} />
        </Source>

        {selectedPlot && <ProjectMapPopup plot={selectedPlot} onClose={() => setSelectedPlot(null)} />}

        {/* Bottom decoration */}
        <div className='pointer-events-none absolute bottom-0 h-32 w-full bg-gradient-to-t from-neutral-black-60 to-transparent' />
      </Map>
    </div>
  );
};

const polygonOutlineLayer: LayerProps = {
  id: 'feat-line',
  type: 'line',
  source: POLYGONS_SOURCE_ID,
  paint: {
    'line-width': 3,
    'line-opacity': 0.6,
    'line-color': 'rgb(255, 255, 255)',
  },
};

const polygonFillLayer: LayerProps = {
  id: 'feat-fill',
  type: 'fill',
  source: POLYGONS_SOURCE_ID,
  paint: {
    'fill-color': ['get', 'color'],
    'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.7, 0.6],
  },
};

const clusterLayer: LayerProps = {
  id: 'clusters',
  type: 'circle',

  source: MARKERS_SOURCE_ID,
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': '#ffffff',
    'circle-stroke-width': 15,
    'circle-stroke-color': '#ffffff',
    'circle-stroke-opacity': 0.4,
    'circle-radius': 20,
  },
};

const clusterCountLayer: LayerProps = {
  id: 'cluster-count',
  type: 'symbol',
  source: MARKERS_SOURCE_ID,
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-size': 12,
  },
};

const unclusteredPointLayer: LayerProps = {
  id: 'unclustered-point',
  type: 'circle',
  source: MARKERS_SOURCE_ID,
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': '#ffffff',
    'circle-stroke-width': 10,
    'circle-stroke-color': '#ffffff',
    'circle-stroke-opacity': 0.4,
    'circle-radius': 15,
  },
};

const iconLayer: LayerProps = {
  id: 'unclustered-point-icon',
  type: 'symbol',
  source: MARKERS_SOURCE_ID,
  filter: ['!', ['has', 'point_count']],
  layout: {
    'icon-image': ['get', 'icon'],
    'icon-size': 0.5,
    'text-field': ['get', 'name'],
    'text-offset': [0, 3],
    'text-anchor': 'bottom',
    'text-size': 12,
  },
  paint: {
    'text-color': '#ffffff',
    'text-halo-color': '#000000',
    'text-halo-width': 2,
  },
};
