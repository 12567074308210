import { ButtonLink, ButtonLinkProps, RiArrowRightSLine, Stack } from 'component-library';
import { cn } from 'component-library/utils';
import { HTMLAttributes, ReactNode } from 'react';

export type TileProps = {
  chart: ReactNode;
  capsuleTitle: ReactNode;
  capsuleContent: ReactNode;
  cta: ReactNode;
  helper: ReactNode;
};

export const Tile = ({ chart, capsuleTitle, capsuleContent, cta, helper }: TileProps) => {
  return (
    <Stack spacing={8} className='h-[300px] justify-between'>
      <Stack spacing={4} className='mt-[30px]'>
        {chart}
        <Stack spacing={2}>
          {capsuleTitle}
          {capsuleContent}
        </Stack>
      </Stack>
      <Stack spacing={4} className='items-start'>
        {cta}
        {helper}
      </Stack>
    </Stack>
  );
};

export const TileCapsuleTitle = ({ className, ...delegated }: HTMLAttributes<HTMLSpanElement>) => {
  return <span className={cn('typography-overline text-text-secondary', className)} {...delegated} />;
};

export const TileCapsuleContent = ({ className, ...delegated }: HTMLAttributes<HTMLSpanElement>) => {
  return <span className={cn('typography-h3', className)} {...delegated} />;
};

export const TileCta = (props: ButtonLinkProps) => {
  return <ButtonLink variant='outline' rightAdornment={<RiArrowRightSLine />} {...props} />;
};

export const TileHelper = ({ className, ...delegated }: HTMLAttributes<HTMLSpanElement>) => {
  return <span className={cn('typography-caption text-text-secondary', className)} {...delegated} />;
};
