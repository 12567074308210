import { cn, Container, Loader, Stack, toast } from 'component-library';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';

import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { SuspenseBoundary, TabsList, TabTrigger } from '@/components';
import { useMembershipType } from '@/hooks/useMembershipType';
import { usePathSet } from '@/hooks/usePathSet';
import { SignedInPageLayout } from '@/layout/signed-in-page';
import { Notices } from '@/pages/shared/components/Notices';
import { useMenuItems } from '@/pages/shared/hooks/useMenuItems';
import { usePlotAggregate } from '@/pages/shared/hooks/usePlotAggregate';
import { usePlotsForProject } from '@/pages/shared/hooks/usePlotsForProject';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';
import { hasProjectTypeImpactProjections } from '@/pages/shared/hooks/utils';
import { buildPath } from '@/utils/buildPath';

import { NoPlotsHero } from './components/NoPlotsHero';
import { ProjectMap } from './components/ProjectMap';
import { ProjectOverview } from './components/ProjectOverview';
import { ProjectSecondaryNav } from './components/ProjectSecondaryNav';
import { RequireProjectableProject } from './components/RequireProjectableProject';
import { ImpactProjectionsPage } from './pages/impact-projections/ImpactProjections.page';
import { ImpactProjectionsPage as ImpactProjectionsPageV2 } from './pages/impact-projections-v2/ImpactProjections.page';
import { LandMonitoringPage } from './pages/land-monitoring/LandMonitoring.page';
import { NCAPage } from './pages/nca/Nca.page';
import { PlotsPage } from './pages/plots/Plots.page';

export const SustainableProductionProjectPage = () => {
  const { t } = useTranslation();

  const menuItems = useMenuItems();
  const projectId = useProjectId();
  const project = useProjectDetailById().data;
  const pathSet = usePathSet();

  const membershipType = useMembershipType();
  const plots = usePlotsForProject().data.results;

  const { plots_requiring_attention: invalidPlotsCount } = usePlotAggregate().data;

  if (plots.length === 0) {
    if (membershipType === MembershipWithOrganizationTypeEnum.land_steward) {
      return (
        <SignedInPageLayout items={menuItems} withCustomContentContainer>
          <ProjectSecondaryNav />
          <NoPlotsHero />
        </SignedInPageLayout>
      );
    }

    toast({
      title: t('shared.projects.project.invalidProjectToast'),
      type: 'error',
    });

    return <Navigate to={pathSet.projects} replace />;
  }

  const hasImpactProjections = hasProjectTypeImpactProjections(project);

  return (
    <SignedInPageLayout items={menuItems} withCustomContentContainer>
      <ProjectSecondaryNav />
      <Container gutterWidth={{ sm: 4 }} className='my-10' data-testid='sustainable-production-project-page'>
        <div className={cn('grid grid-rows-[auto] gap-10', 'md:w-auto md:grid-cols-2')}>
          <ProjectMap />
          <ProjectOverview />
        </div>

        <TabsList className='my-10 justify-start sm:justify-center'>
          <TabTrigger path={buildPath(pathSet.projectDetails, { pathParams: { projectId } })}>
            {t('shared.projects.labels.landMonitoring')}
          </TabTrigger>
          <TabTrigger path={buildPath(pathSet.nca, { pathParams: { projectId } })}>
            {t('shared.projects.labels.naturalCapitalAccount')}
          </TabTrigger>
          <TabTrigger path={buildPath(pathSet.plots, { pathParams: { projectId } })}>
            <Stack direction='row' spacing={2} center>
              {t('shared.projects.labels.plots')}
              {invalidPlotsCount > 0 && (
                <span
                  data-testid='invalid-plots-count'
                  className='typography-body2Semibold flex aspect-auto items-center justify-center rounded-full bg-warning px-2 text-primary-100'
                >
                  {invalidPlotsCount}
                </span>
              )}
            </Stack>
          </TabTrigger>
          {hasImpactProjections && (
            <TabTrigger path={buildPath(pathSet.impactProjections, { pathParams: { projectId } })}>
              {t('shared.projects.labels.impactProjections')}
            </TabTrigger>
          )}
        </TabsList>
        <Stack className='my-6'>
          <Notices />
        </Stack>

        <SuspenseBoundary
          fallback={
            <Stack center className='h-[600px]'>
              <Loader />
            </Stack>
          }
        >
          <Routes>
            <Route index element={<LandMonitoringPage />} />
            <Route path='nca' element={<NCAPage />} />
            <Route path='plots' element={<PlotsPage />} />
            <Route element={<RequireProjectableProject />}>
              <Route path='impact-projections' element={<ImpactProjectionsPage />} />
              <Route path='impact-projections-v2' element={<ImpactProjectionsPageV2 />} />
            </Route>
          </Routes>
        </SuspenseBoundary>
      </Container>
    </SignedInPageLayout>
  );
};
