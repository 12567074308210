import BareAndSparseVegetationIcon from '@assets/images/icons/landtype-bare-and-sparse-vegetation.png';
import BuiltUpAreasIcon from '@assets/images/icons/landtype-built-up-areas.png';
import CroplandIcon from '@assets/images/icons/landtype-cropland.png';
import ForestryIcon from '@assets/images/icons/landtype-forestry.png';
import GrasslandIcon from '@assets/images/icons/landtype-grassland.png';
import HerbaceousWetlandIcon from '@assets/images/icons/landtype-herbaceous-wetland.png';
import MangrovesIcon from '@assets/images/icons/landtype-mangroves.png';
import MossAndLichensIcon from '@assets/images/icons/landtype-moss-and-lichens.png';
import PermanentWaterBodiesIcon from '@assets/images/icons/landtype-permanent-water-bodies.png';
import ShrublandIcon from '@assets/images/icons/landtype-shrubland.png';
import SnowAndIceIcon from '@assets/images/icons/landtype-snow-and-ice.png';
import TreeCoverIcon from '@assets/images/icons/landtype-tree-cover.png';
import UnknownLandtypeIcon from '@assets/images/icons/landtype-unknown.png';

import { PlotType } from '@/api/rest/resources/types/plot';
import { Logger } from '@/lib/logs/logger';

export const getPNGIconForLandType = (landType: PlotType) => {
  const exhaustivenessCheck = (p: never) => {
    Logger.error(`No icon is defined for the plot type "${p}"`);
    return UnknownLandtypeIcon;
  };

  switch (landType) {
    case PlotType.BARE_AND_SPARSE_VEGETATION:
      return BareAndSparseVegetationIcon;
    case PlotType.BUILT_UP:
      return BuiltUpAreasIcon;
    case PlotType.CROPLAND:
      return CroplandIcon;
    case PlotType.FORESTRY:
      return ForestryIcon;
    case PlotType.GRASSLAND:
      return GrasslandIcon;
    case PlotType.HERBACEOUS_WETLAND:
      return HerbaceousWetlandIcon;
    case PlotType.MANGROVES:
      return MangrovesIcon;
    case PlotType.MOSS_AND_LICHENS:
      return MossAndLichensIcon;
    case PlotType.PERMANENT_WATER_BODIES:
      return PermanentWaterBodiesIcon;
    case PlotType.SHRUBLAND:
      return ShrublandIcon;
    case PlotType.SNOW_AND_ICE:
      return SnowAndIceIcon;
    case PlotType.TREE_COVER:
      return TreeCoverIcon;
    case PlotType.UNDEFINED:
      return UnknownLandtypeIcon;
    default:
      return exhaustivenessCheck(landType);
  }
};
