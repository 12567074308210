import { Stack } from 'component-library';
import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { PlotReportFactLabel, PlotReportFactLabelNameEnum, ValueClass } from '@/api/rest/resources/types/fact';
import { UnitEnum } from '@/api/rest/resources/types/units';
import { getDisplayNumber } from '@/hooks/useDisplayNumber';
import { Logger } from '@/lib/logs/logger';
import { formatUnit } from '@/utils/formatting';
import { printYear } from '@/utils/formatting/date';

import { ValueClassPill } from '../Pill/ValueClassPill';
import { Area, Chart, ComposedChart, getXAxisProps, TooltipProps } from './components/Chart';
import { ChartLegendBar } from './components/LegendBar';
import {
  ChartTooltip,
  ChartTooltipConfidenceInterval,
  ChartTooltipDot,
  ChartTooltipTextPrimary,
  ChartTooltipTextSecondary,
} from './components/Tooltip';
import { ChartLegendBarItem } from './components/types';
import { RenderConfidenceInterval } from './ConfidenceInterval';
import { useConfidenceIntervalLegendItem } from './hooks/useConfidenceIntervalLegendItem';
import { NoChartData } from './NoChartData';
import { ChartProps } from './types';
import { getChartDataFromGraphFact, getXAxisDomainForHistoricGraph, sortChartData } from './utils';

const CANOPY_COVER_LABEL = 'global.analysis.canopyCover';
const UNIT = formatUnit(UnitEnum['%']);

const chartColors = {
  canopyCover: {
    stroke: '#65D365',
    fill: '#83CE83',
  },
  confidenceInterval: {
    fill: '#2E876C33',
  },
} as const;

const factNames = {
  // TODO: MVP-4772 - change with forest fact
  permanent_soil_carbon_total: 'canopyCover',
};

export const CanopyCoverHistoricalChart: FC<HTMLAttributes<HTMLDivElement> & ChartProps> = ({
  data,
  showTooltip,
  ...delegated
}) => {
  const { t } = useTranslation();

  const dateDataMap = getChartDataFromGraphFact(data, factNames); // TODO: MVP-4772 - check if this function needs a formatter when a real forest fact is used
  const sortedChartData = sortChartData(dateDataMap, (date) => new Date(date).getTime());

  const firstTick = sortedChartData.at(0);
  const lastTick = sortedChartData.at(-1);

  if (!firstTick || !lastTick) {
    Logger.error('Not enough data to render chart');
    return (
      <div className='relative flex h-full w-full flex-col justify-center' {...delegated}>
        <NoChartData />
      </div>
    );
  }
  const xTicksDomain = getXAxisDomainForHistoricGraph(firstTick.label, lastTick.label);
  const xAxisProps = {
    ...getXAxisProps(xTicksDomain),
    tickFormatter: printYear,
  };

  return (
    <>
      <Chart.Container
        {...delegated}
        data={sortedChartData}
        config={{
          canopyCover: {
            label: t(CANOPY_COVER_LABEL),
            color: chartColors.canopyCover.stroke,
          },
        }}
      >
        <ComposedChart data={sortedChartData}>
          <defs>
            <linearGradient id='canopyCover' x1='0' y1='-2.6' x2='0' y2='1'>
              <stop offset='0%' stopColor={chartColors.canopyCover.fill} stopOpacity={1} />
              <stop offset='100%' stopColor={chartColors.canopyCover.fill} stopOpacity={0} />
            </linearGradient>
          </defs>
          {RenderConfidenceInterval({
            dataKey: 'canopyCover.confidence_interval',
            type: 'monotone',
            fill: chartColors.confidenceInterval.fill,
            id: 'canopy-cover-confidence-interval',
          })}
          <Area
            type='monotone'
            isAnimationActive={false}
            stroke='var(--color-canopyCover)'
            strokeWidth={2}
            fill='url(#canopyCover)'
            fillOpacity={1}
            dataKey='canopyCover.value'
            id='canopy-cover-area'
            connectNulls
            activeDot={ChartTooltipDot}
          />
          <Chart.XAxis {...xAxisProps} className='canopy-cover-x-axis' />
          <Chart.YAxis domain={[0, 100]} className='canopy-cover-y-axis'>
            <Chart.Label>{UNIT}</Chart.Label>
          </Chart.YAxis>
          {showTooltip && <Chart.Tooltip content={(props) => <CustomTooltip {...props} />} />}
        </ComposedChart>
      </Chart.Container>
      <CustomLegendBar />
    </>
  );
};

const CustomLegendBar = () => {
  const { t } = useTranslation();
  const confidenceIntervalLegendItem = useConfidenceIntervalLegendItem({
    confidenceIntervalColor: chartColors.confidenceInterval.fill,
  });

  const legendBarItems: ChartLegendBarItem[] = [
    {
      label: t(CANOPY_COVER_LABEL),
      color: chartColors.canopyCover.fill,
    },
    ...confidenceIntervalLegendItem,
  ];

  return <ChartLegendBar items={legendBarItems} className='sm:ml-[28px]' data-testid='canopy-cover-chart-legend' />;
};

const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
  const { t } = useTranslation();

  const canopyCoverValue = payload?.find((data) => data.dataKey === 'canopyCover.value');
  const canopyCoverDisplay =
    canopyCoverValue?.value != null
      ? `${getDisplayNumber(canopyCoverValue?.value as string, window.navigator.language)} ${UNIT}`
      : t('global.analysis.noData');

  const dataPointHasLabel = canopyCoverValue && canopyCoverValue?.payload?.canopyCover?.labels?.length > 0;
  const dataPointValueClass = dataPointHasLabel
    ? canopyCoverValue.payload.canopyCover.labels.find(
        (dataLabel: PlotReportFactLabel) => dataLabel.name === PlotReportFactLabelNameEnum.value_class,
      )
    : undefined;
  const dataLabel = dataPointValueClass?.value as ValueClass;

  if (!active) return null;

  return (
    <ChartTooltip>
      <Stack spacing={8} direction='row' className='justify-between'>
        <ChartTooltipTextSecondary>{printYear(label)}</ChartTooltipTextSecondary>
        {dataLabel && <ValueClassPill title={dataLabel} />}
      </Stack>
      <Stack spacing={8} direction='row'>
        <ChartTooltipTextPrimary>
          {t('shared.ncaDetail.details.forest.labels.averageCanopyCover')}
        </ChartTooltipTextPrimary>
        <ChartTooltipTextPrimary>{canopyCoverDisplay}</ChartTooltipTextPrimary>
      </Stack>
      <ChartTooltipConfidenceInterval ciPct={canopyCoverValue?.payload?.canopyCover?.confidence_interval_pct} />
    </ChartTooltip>
  );
};

export { CustomTooltip as CustomTooltipCanopyCoverHistorical };
