import { useTranslation } from 'react-i18next';
import { Area, AreaChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { ProjectStatus } from '@/api/rest/resources/types/project';
import { UnitEnum } from '@/api/rest/resources/types/units';
import { getXAxisDomainForHistoricGraph, minMaxAxisDomain } from '@/components/Charts/utils';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { useMembershipType } from '@/hooks/useMembershipType';
import { useProject } from '@/pages/landsteward/hooks/useProject';
import { useProjectFact } from '@/pages/shared/hooks/useProjectFact';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { formatFactKilogramsToTonnes, formatUnit } from '@/utils/formatting';

import { NoProjectData } from '../../../components/NoProjectData';
import { Tile, TileCapsuleContent, TileCapsuleTitle, TileCta, TileHelper } from './Tile';

export const AboveGroundCarbonTile = () => {
  const { t } = useTranslation();
  const project = useProject().data;
  const pathSet =
    useMembershipType() === MembershipWithOrganizationTypeEnum.land_steward ? paths.landSteward : paths.buyer;

  const soilCarbonHistoricPerHaGraph = useProjectFact<CarbonHistoricGraphData[]>( // TODO 4772 AG carbon should not be soil
    R1FactType.r1_soil_carbon_historic_per_ha_graph,
  )
    ?.value?.filter((d) => d.name === 'permanent_soil_carbon_per_ha') // TODO 4772 AG carbon should not be soil
    // @ts-ignore typescript does not allow arithmetic operations between date objects
    .sort((pointA, pointB) => new Date(pointA.date) - new Date(pointB.date))
    .map((d) => ({ ...d, date: new Date(d.date).getTime() }));

  const carbonAgLatestAnalysis = formatFactKilogramsToTonnes(
    useProjectFact<number>(R1FactType.r1_carbon_storage_bg_per_ha), // TODO MVP-4772 AG carbon should not be soil
  );

  const chartData = soilCarbonHistoricPerHaGraph;

  let carbonAgLatestAnalysisDisplay = `${useDisplayNumber(carbonAgLatestAnalysis?.value ?? 0)} ${formatUnit(
    carbonAgLatestAnalysis?.unit ?? UnitEnum['t/ha'],
  )}`;
  carbonAgLatestAnalysisDisplay = carbonAgLatestAnalysis?.value != null ? carbonAgLatestAnalysisDisplay : '-';

  const noChartData = !chartData?.length;

  if (noChartData) {
    return <NoProjectData />;
  }

  return (
    <Tile
      chart={<Chart chartData={chartData} />}
      capsuleTitle={
        <TileCapsuleTitle>
          {t('shared.ncaDetail.details.aboveGroundCarbon.labels.aboveGroundCarbonStorage')}
        </TileCapsuleTitle>
      }
      capsuleContent={<TileCapsuleContent>{carbonAgLatestAnalysisDisplay}</TileCapsuleContent>}
      cta={
        <TileCta to={buildPath(pathSet.projectDetailsCarbon, { pathParams: { projectId: project.id } })}>
          {t('shared.projects.project.landMonitoring.carbon.labels.seeAllCarbonIndicators')}
        </TileCta>
      }
      helper={<TileHelper>{t('shared.projects.project.landMonitoring.carbon.helper')}</TileHelper>}
    />
  );
};

type CarbonHistoricGraphData = {
  // TODO: MVP-4772 - User correct GraphData
  date: number;
  name: 'permanent_soil_carbon_per_ha';
  value: number;
  unit: 't';
};

const Chart = ({ chartData }: { chartData: CarbonHistoricGraphData[] }) => {
  const project = useProject().data;
  const isOutdatedValue = project.status !== ProjectStatus.analysed;

  const firstTick = chartData.at(0)?.date;
  const lastTick = chartData.at(-1)?.date;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const xTicksDomain = getXAxisDomainForHistoricGraph(firstTick!, lastTick!) ?? [
    new Date().getFullYear(),
    new Date().getFullYear(),
  ];
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const startYear = xTicksDomain[0]!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const endYear = xTicksDomain[1]!;
  const ticksRange = Array(endYear - startYear + 1).keys();
  const xTicks = Array.from(ticksRange).map((x) => new Date(`12-31-${startYear + x}`).getTime());
  return (
    <ResponsiveContainer width='100%' height={80}>
      <AreaChart
        data={chartData}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <defs>
          <linearGradient id='carbonHistorical' x1='0' y1='-2.6' x2='0' y2='1'>
            <stop offset='0%' stopColor={isOutdatedValue ? '#B4B4B4' : '#C3F093'} stopOpacity={1} />
            <stop offset='100%' stopColor={isOutdatedValue ? '#B4B4B4' : '#C3F093'} stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          isAnimationActive={false}
          type='monotone'
          dataKey='value'
          stroke={isOutdatedValue ? '#595959' : '#C3F093'}
          strokeWidth={2}
          fill='url(#carbonHistorical)'
          fillOpacity={1}
        />
        <XAxis
          hide
          dataKey='date'
          scale='time'
          type='number'
          domain={['dataMin', 'dataMax']}
          ticks={xTicks}
          padding={{ left: 8, right: 8 }}
        />
        <YAxis
          hide
          dataKey='value'
          type='number'
          scale='sequential'
          width={0}
          domain={minMaxAxisDomain(0.8, 1.2)}
          padding={{ top: 8, bottom: 8 }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
