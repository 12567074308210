// TODO: (MVP-1132): these types should be generated automatically based on the API's Swagger documentation

import { UnitEnum } from './units';

export enum NCAReportBalanceValueType {
  opening_value = 'opening_value',
  latest_value = 'latest_value',
  one_year_change_value = 'one_year_change_value',
  one_year_change_perc = 'one_year_change_perc',
}

export enum NCAReportBalanceType {
  ABOVE_GROUND_CARBON = 'CARBON', // TODO: MVP-4772 check balance input received from the BE for both carbons
  BELOW_GROUND_CARBON = 'CARBON',
  WATER = 'WATER',
  BIODIVERSITY = 'BIODIVERSITY',
}

export type NCABalance<T = number | string | null | unknown> = {
  name: NCAReportBalanceType;
  type: NCAReportBalanceValueType;
  value: T;
  unit: UnitEnum;
  reference_date: string | null;
};

export interface NCAReportBalanceValue<T = number | string | null | unknown> extends NCABalance {
  value: T;
  unit: UnitEnum;
  reference_date: string | null;
}

export type NCAReportBalance = {
  name: NCAReportBalanceType;
  [NCAReportBalanceValueType.opening_value]: NCAReportBalanceValue;
  [NCAReportBalanceValueType.latest_value]: NCAReportBalanceValue;
  [NCAReportBalanceValueType.one_year_change_value]: NCAReportBalanceValue;
  [NCAReportBalanceValueType.one_year_change_perc]: NCAReportBalanceValue;
};

export const SIGNED_NCA_BALANCE_TYPES = [
  NCAReportBalanceValueType.one_year_change_value,
  NCAReportBalanceValueType.one_year_change_perc,
];
