import { useQueryClient } from '@tanstack/react-query';
import i18next from 'i18next';
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { usePostAuthenticationOtp, usePostAuthenticationSignIn } from '@/api/rest/resources/authentication';
import { usePostTokenSignOut } from '@/api/rest/resources/token';
import {
  PostAuthenticationOtpRequestBodyData,
  PostAuthenticationOtpSuccessDto,
  PostAuthenticationSignInRequestBodyData,
  PostAuthenticationSignInSuccessDto,
} from '@/api/rest/resources/types/authentication';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import {
  PostUserResetPasswordRequestBodyData,
  PostUserResetPasswordSuccessDto,
  PostUserSignUpRequestBodyData,
  PostUserSignUpSuccessDto,
  User,
} from '@/api/rest/resources/types/user';
import { usePostUserResetPassword, usePostUserSignUp } from '@/api/rest/resources/user';
import { paths } from '@/routing';

import { Sentry } from '../logs';
import { Logger } from '../logs/logger';
import { StorageStrategy } from '../storage/StorageStrategy';
import { TokenStorageKeys } from '.';
import { AuthenticatedUser, AuthenticationProvider, Authenticator } from './AuthenticationContext';
import { tokenRefresher } from './TokenRefresher';

function isPostAuthenticationOtpSuccessDto(
  value: PostAuthenticationOtpSuccessDto | PostAuthenticationSignInSuccessDto,
): value is PostAuthenticationOtpSuccessDto {
  return value && typeof (value as PostAuthenticationOtpSuccessDto).impersonated_by === 'string';
}

interface CustomLandlerAuthenticationProviderProps extends PropsWithChildren {
  tokenStorage: StorageStrategy;
}

type AuthFunctions = Omit<
  Authenticator,
  'accessToken' | 'isLoading' | 'isSignedIn' | 'isBooted' | 'authenticatedUser' | 'setUser'
>;

export const CustomLandlerAuthenticationProvider: React.FC<CustomLandlerAuthenticationProviderProps> = ({
  children,
  tokenStorage,
}) => {
  const [authenticatedUser, setAuthenticatedUser] = useState<AuthenticatedUser>({ user: null });
  const [accessToken, setAccessToken] = useState<string | undefined>();
  const { mutate: signUp, isPending: isSigningUp } = usePostUserSignUp();
  const { mutate: signIn, isPending: isSigningIn } = usePostAuthenticationSignIn();
  const { mutate: signInWithToken, isPending: isSigningInWithToken } = usePostAuthenticationOtp();
  const { mutate: resetPassword, isPending: isResettingPassword } = usePostUserResetPassword();
  const { mutateAsync: tokenSignOut } = usePostTokenSignOut();
  const [isTokenLoadedFromStorage, setIsTokenLoadedFromStorage] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const setUser = (updatedUser: User) => {
    if (!updatedUser) {
      throw new Error('The user can not be null');
    }
    setAuthenticatedUser((prevAuthenticatedUser) => {
      const updatedAuthenticatedUser = { ...prevAuthenticatedUser, user: updatedUser };
      const updatedAuthenticatedUserForToken = { ...updatedAuthenticatedUser, user: updatedUser };

      tokenStorage.setItem(TokenStorageKeys.AUTHENTICATED_USER, JSON.stringify(updatedAuthenticatedUserForToken));

      return updatedAuthenticatedUser;
    });
    Sentry.setUser(updatedUser);
  };

  const loadUserFromTokenStorage = useCallback(() => {
    const loadedAuthenticatedUser = tokenStorage.getItem(TokenStorageKeys.AUTHENTICATED_USER);
    if (loadedAuthenticatedUser) {
      try {
        const json = JSON.parse(loadedAuthenticatedUser);

        setAuthenticatedUser(json);
        Sentry.setUser(json.user);
      } catch (e) {
        tokenStorage.removeItem(TokenStorageKeys.AUTHENTICATED_USER);
      }
    }
  }, [tokenStorage, setAuthenticatedUser]);

  const handleSignInSuccess = async (
    response:
      | PostUserSignUpSuccessDto
      | PostAuthenticationSignInSuccessDto
      | PostAuthenticationOtpSuccessDto
      | PostUserResetPasswordSuccessDto,
  ) => {
    const isOtpResponse = isPostAuthenticationOtpSuccessDto(response);

    const impersonatedBy = isOtpResponse ? (response.impersonated_by ?? undefined) : undefined; // make sure its undefined and not null
    const authenticatedUserResponse = {
      impersonatedBy,
      user: response.user,
    };

    const { user } = response;

    // 'other' is being used as a catch all on the BE and is not supposed to happen
    if (user.membership.type === MembershipWithOrganizationTypeEnum.other) {
      throw new Error(`Unhandled membership type ${user.membership.type}`);
    }

    tokenStorage.setItem(TokenStorageKeys.AUTH_TOKEN, response.token.access);
    tokenStorage.setItem(TokenStorageKeys.REFRESH_TOKEN, response.token.refresh);
    tokenStorage.setItem(TokenStorageKeys.AUTHENTICATED_USER, JSON.stringify(authenticatedUserResponse));

    if (i18next.language !== user.language) {
      await i18next.changeLanguage(user.language);
    }

    setAccessToken(response.token.access);
    setAuthenticatedUser({
      impersonatedBy,
      user,
    });

    Sentry.setUser(user);
  };

  const resetAuth = () => {
    tokenStorage.removeItem(TokenStorageKeys.AUTH_TOKEN);
    tokenStorage.removeItem(TokenStorageKeys.REFRESH_TOKEN);
    tokenStorage.removeItem(TokenStorageKeys.AUTHENTICATED_USER);

    setAccessToken(undefined);

    setAuthenticatedUser({ user: null });

    Sentry.setUser(null);

    queryClient.removeQueries();
  };

  const auth = useMemo<AuthFunctions>(
    () => ({
      async signUp(data: PostUserSignUpRequestBodyData): Promise<void> {
        return new Promise((resolve, reject) => {
          signUp(
            { bodyData: data },
            {
              onSuccess: async (response) => handleSignInSuccess(response).then(resolve).catch(reject),
              onError: (error) => reject(error),
            },
          );
        });
      },
      async signIn(data: PostAuthenticationSignInRequestBodyData): Promise<void> {
        return new Promise((resolve, reject) => {
          signIn(
            { bodyData: data },
            {
              onSuccess: async (response) => handleSignInSuccess(response).then(resolve).catch(reject),
              onError: (error) => reject(error),
            },
          );
        });
      },
      async signInWithToken(data: PostAuthenticationOtpRequestBodyData): Promise<void> {
        return new Promise((resolve, reject) => {
          signInWithToken(
            { bodyData: data },
            {
              onSuccess: async (response) => handleSignInSuccess(response).then(resolve).catch(reject),
              onError: (error) => reject(error),
            },
          );
        });
      },
      async resetPassword(data: PostUserResetPasswordRequestBodyData): Promise<void> {
        return new Promise((resolve, reject) => {
          resetPassword(
            { bodyData: data },
            {
              onSuccess: async (response) => handleSignInSuccess(response).then(resolve).catch(reject),
              onError: (error) => reject(error),
            },
          );
        });
      },
      async signOut(): Promise<void> {
        try {
          /**
           * TODO: MVP-1834
           *
           * Originally, we didn't need to explicitly navigate() here, because
           * `RequireAuth` would auto-redirect to `/login` when accessToken is
           * removed. However, `RequireAuth` also sets `{ state: { loginDeeplink:
           * '...' } }` while navigating to `/login`, which is used to determine
           * whether to show a regular login page or an access denied (login
           * required) page. Navigating early here prevents the access denied page
           * from showing up when clicking logout. This is a temporary solution
           * that isn't airtight, since it relies on the React render cycle and
           * batching of state updates.
           */
          navigate(paths.login);

          resetAuth();
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          Logger.error(`Failed signing out: ${e.message}`);
        }
      },

      async signOutAndInvalidateSession() {
        const authToken = tokenStorage.getItem(TokenStorageKeys.AUTH_TOKEN);
        const refreshToken = tokenStorage.getItem(TokenStorageKeys.REFRESH_TOKEN);

        if (authToken && refreshToken) {
          await tokenSignOut({ bodyData: { access: authToken, refresh: refreshToken } });
        }

        await auth.signOut();
      },
    }),
    // dependency check disabled to not rely on the mutations from react-query to be memoized
    [tokenStorage], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const syncUserFromLocalStorage = useCallback(async () => {
    const loadedAuthenticatedUser = tokenStorage.getItem(TokenStorageKeys.AUTHENTICATED_USER);
    if (loadedAuthenticatedUser) {
      try {
        const json = JSON.parse(loadedAuthenticatedUser);

        setAuthenticatedUser({ ...json, user: json.user });
        Sentry.setUser(json.user);
      } catch (e) {
        Logger.warn(
          "Failed to sync user from local storage. This can happen when the user's data structure changed. So it  can be ignored in case of a new release with user structure changes.",
        );
        // NOTE: It could be also a solution to refetch the user instead of signing out
        auth.signOut();
      }
    }
  }, [tokenStorage, setAuthenticatedUser, auth]);

  useEffect(() => {
    const storageAccessToken = tokenStorage.getItem(TokenStorageKeys.AUTH_TOKEN);
    if (storageAccessToken) {
      setAccessToken(storageAccessToken);
    }

    setIsTokenLoadedFromStorage(true);

    syncUserFromLocalStorage();

    return tokenRefresher.subscribeTokenChanges(CustomLandlerAuthenticationProvider.name, {
      onRefresh: async (newToken) => {
        setAccessToken(newToken);
      },
      onInvalidate: async () => {
        await auth.signOut().then(() => {
          navigate(paths.login, { state: { loginDeeplink: `${location.pathname}${location.search}` } });
        });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, syncUserFromLocalStorage, tokenStorage]);

  const updateStateOnWindowFocus = () => {
    loadUserFromTokenStorage();

    const loadedAuthenticatedUser = tokenStorage.getItem(TokenStorageKeys.AUTHENTICATED_USER);
    const storageAccessToken = tokenStorage.getItem(TokenStorageKeys.AUTH_TOKEN);

    // if any of the values are missing, we are going to reset the state, essentially logging out the user
    if (!loadedAuthenticatedUser || !storageAccessToken) {
      resetAuth();
      return;
    }

    // if the user is already signed in, the loadUserFromTokenStorage() will set the user for us,
    // but we still have to set the access token manually here for the user to be truly signed in
    if (loadedAuthenticatedUser && storageAccessToken) {
      setAccessToken(storageAccessToken);

      setIsTokenLoadedFromStorage(true);
    }
  };

  // eslint-disable-next-line prefer-arrow-callback
  useEffect(function applyEventListenerToUpdateStateOnWindowFocus() {
    window.addEventListener('focus', updateStateOnWindowFocus);

    return () => {
      window.removeEventListener('focus', updateStateOnWindowFocus);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthenticationProvider
      value={{
        ...auth,
        accessToken,
        isLoading: isSigningUp || isSigningIn || isSigningInWithToken || isResettingPassword,
        isSignedIn: !!accessToken,
        isBooted: isTokenLoadedFromStorage && (!accessToken || !!authenticatedUser.user),
        authenticatedUser,
        setUser,
      }}
    >
      {children}
    </AuthenticationProvider>
  );
};
