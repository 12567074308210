import { Stack } from 'component-library';
import { Trans, useTranslation } from 'react-i18next';

import { useMethodologyPageLink } from '@/pages/shared/projects/pages/project/pages/conservation/hooks/useMethodologyPageLink';

import {
  Explainer,
  ExplainerBody,
  ExplainerContent,
  ExplainerCopy,
  ExplainerTitle,
  ExplainerTrigger,
  MethodologyLink,
} from './Explainer';

export const FloraAndFaunaExplainer = () => {
  const { t } = useTranslation();
  const methodologyPageLink = useMethodologyPageLink();

  return (
    <Explainer>
      <ExplainerTrigger data-testid='flora-and-fauna-explainer'>{t('global.howDoWeMeasureThis')}</ExplainerTrigger>
      <ExplainerContent>
        <ExplainerTitle>
          {t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.explainers.howDoWeMeasure.title')}
        </ExplainerTitle>
        <Stack spacing={8} className='items-start pb-6'>
          <ExplainerBody className='pb-0'>
            <ExplainerCopy
              body={
                <div className='columns-1 leading-[22px] text-text-secondary md:columns-2'>
                  <Trans i18nKey='shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.explainers.howDoWeMeasure.body' />
                </div>
              }
            />
          </ExplainerBody>
          <MethodologyLink to={methodologyPageLink} />
        </Stack>
      </ExplainerContent>
    </Explainer>
  );
};
