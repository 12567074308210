import { Stack } from 'component-library';
import { useTranslation } from 'react-i18next';

import { BenchmarkType, PlotReportFactLabelNameEnum, R1FactType } from '@/api/rest/resources/types/fact';
import { BiodiversityZoneChart } from '@/components/Charts';
import { ChartHeading } from '@/components/Charts/components/ChartHeading';
import { GraphFact } from '@/components/Charts/types';
import { useBenchmarksForProject } from '@/pages/shared/hooks/useBenchmarkForFact';
import { useProjectFact } from '@/pages/shared/hooks/useProjectFact';

import { NoProjectData } from '../../../../components/NoProjectData';

export const BiodiversityCardGraphTile = () => {
  const { t } = useTranslation();

  const biodiversityZonePercentHistoricGraph = useProjectFact<GraphFact[]>(
    R1FactType.r1_biodiversity_zone_percent_historic_graph,
  );
  const bpHistoricData = biodiversityZonePercentHistoricGraph?.value;

  // TODO: MVP-3284 centralize this benchmarking formatting to a hook?
  const bpLowBenchmark = useProjectFact<GraphFact[]>(R1FactType.biodiversity_buffer_zone_pct_benchmark_graph, null, {
    [PlotReportFactLabelNameEnum.benchmark]: BenchmarkType.low,
  });
  const bpLowBenchmarkData = (bpLowBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_low` };
  });
  const bpModerateBenchmark = useProjectFact<GraphFact[]>(
    R1FactType.biodiversity_buffer_zone_pct_benchmark_graph,
    null,
    {
      [PlotReportFactLabelNameEnum.benchmark]: BenchmarkType.moderate,
    },
  );
  const bpModerateBenchmarkData = (bpModerateBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_moderate` };
  });
  const bpHighBenchmark = useProjectFact<GraphFact[]>(R1FactType.biodiversity_buffer_zone_pct_benchmark_graph, null, {
    [PlotReportFactLabelNameEnum.benchmark]: BenchmarkType.high,
  });
  const bpHighBenchmarkData = (bpHighBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_high` };
  });

  const bpBenchmarks = useBenchmarksForProject(R1FactType.biodiversity_buffer_zone_pct_benchmark_graph);

  /**
   * We only null check for bpHistoricData since bpHistoricData is the primary source data of the graph
   * reference lines are displayed only when bpHistoricData is rendered
   */
  const noChartData = !bpHistoricData?.length;
  if (noChartData) {
    return <NoProjectData />;
  }

  const chartData = [...bpHistoricData, ...bpLowBenchmarkData, ...bpModerateBenchmarkData, ...bpHighBenchmarkData];

  return (
    <Stack center data-testid='biodiversity-graph-tile'>
      <Stack spacing={2.5} className='w-full items-center justify-center px-1 py-6 lg:items-start lg:p-6 lg:pl-1'>
        <ChartHeading
          heading={t('shared.ncaDetail.details.biodiversity.labels.historicalGraphHeadline')}
          popover={{
            title: t('shared.ncaDetail.details.biodiversity.historicalData.title'),
            body: t('shared.ncaDetail.details.biodiversity.historicalData.body'),
          }}
          className='w-full px-6 sm:px-0'
        />
        <BiodiversityZoneChart
          data={chartData}
          benchmarks={bpBenchmarks}
          showTooltip={true}
          className='relative w-full'
          data-testid='biodiversity-zone-chart'
        />
      </Stack>
    </Stack>
  );
};
