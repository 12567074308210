import { useTranslation } from 'react-i18next';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { ProjectStatus } from '@/api/rest/resources/types/project';
import { UnitEnum } from '@/api/rest/resources/types/units';
import { chartColors } from '@/components/Charts';
import { GraphFact } from '@/components/Charts/types';
import { getXAxisDomainForHistoricGraph, minMaxAxisDomain } from '@/components/Charts/utils';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { useMembershipType } from '@/hooks/useMembershipType';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';
import { useProjectFact } from '@/pages/shared/hooks/useProjectFact';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { formatUnit } from '@/utils/formatting';

import { NoProjectData } from '../../../components/NoProjectData';
import { Tile, TileCapsuleContent, TileCapsuleTitle, TileCta, TileHelper } from './Tile';

export const ForestStructureTile = () => {
  const { t } = useTranslation();
  const project = useProjectDetailById().data;
  const pathSet =
    useMembershipType() === MembershipWithOrganizationTypeEnum.land_steward ? paths.landSteward : paths.buyer;

  // TODO: MVP-4772 - change with forest fact
  const forestStructureData = useProjectFact<GraphFact[]>(R1FactType.r1_soil_carbon_historic_per_ha_graph)
    ?.value // @ts-ignore typescript does not allow arithmetic operations between date objects
    ?.sort((pointA, pointB) => new Date(pointA.date) - new Date(pointB.date))
    .map((d) => ({ ...d, date: new Date(d.date).getTime() }));

  // TODO: MVP-4772 - change with forest fact
  const averageCanopyHeightLatestAnalysis = useProjectFact<number>(R1FactType.r1_carbon_bg_latest_analysis_total);

  const chartData = forestStructureData;

  let averageCanopyHeightLatestAnalysisDisplay = `${useDisplayNumber(
    averageCanopyHeightLatestAnalysis?.value ?? 0,
  )} ${formatUnit(averageCanopyHeightLatestAnalysis?.unit ?? UnitEnum.m)}`;
  averageCanopyHeightLatestAnalysisDisplay =
    averageCanopyHeightLatestAnalysis?.value != null ? averageCanopyHeightLatestAnalysisDisplay : '-';

  const noChartData = !chartData?.length;

  if (noChartData) {
    return <NoProjectData />;
  }

  return (
    <Tile
      chart={<Chart chartData={chartData} />}
      capsuleTitle={
        <TileCapsuleTitle>{t('shared.ncaDetail.details.forest.labels.averageCanopyHeight')}</TileCapsuleTitle>
      }
      capsuleContent={<TileCapsuleContent>{averageCanopyHeightLatestAnalysisDisplay}</TileCapsuleContent>}
      cta={
        <TileCta to={buildPath(pathSet.projectDetailsForest, { pathParams: { projectId: project.id } })}>
          {t('shared.projects.project.landMonitoring.forest.labels.seeMoreDetailsDeforestation')}
        </TileCta>
      }
      helper={<TileHelper>{t('shared.projects.project.landMonitoring.forest.helper')}</TileHelper>}
    />
  );
};

const Chart = ({ chartData }: { chartData: (Omit<GraphFact, 'date'> & { date: string | number })[] }) => {
  const project = useProjectDetailById().data;
  const isOutdatedValue = project.status !== ProjectStatus.analysed;

  const firstTick = chartData.at(0)?.date;
  const lastTick = chartData.at(-1)?.date;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const xTicksDomain = getXAxisDomainForHistoricGraph(firstTick!, lastTick!) ?? [
    new Date().getFullYear(),
    new Date().getFullYear(),
  ];
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const startYear = xTicksDomain[0]!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const endYear = xTicksDomain[1]!;
  const ticksRange = Array(endYear - startYear + 1).keys();
  const xTicks = Array.from(ticksRange).map((x) => new Date(`12-31-${startYear + x}`).getTime());

  return (
    <ResponsiveContainer width='100%' height={80}>
      <BarChart
        data={chartData}
        margin={{
          top: 0,
          right: 0,
          left: 16,
          bottom: 0,
        }}
      >
        <defs>
          <linearGradient id='canopyHeight' x1='0' y1='-2.6' x2='0' y2='1'>
            <stop
              offset='100%'
              stopColor={isOutdatedValue ? '#B4B4B4' : chartColors.canopyHeight.fill}
              stopOpacity={1}
            />
            <stop
              offset='100%'
              stopColor={isOutdatedValue ? '#B4B4B4' : chartColors.canopyHeight.fill}
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        <Bar isAnimationActive={false} dataKey='value' fill='url(#canopyHeight)' radius={[4, 4, 0, 0]} />
        <XAxis
          hide
          dataKey='date'
          scale='time'
          type='number'
          domain={['dataMin', 'dataMax']}
          ticks={xTicks}
          padding={{ left: 8, right: 8 }}
        />
        <YAxis
          hide
          dataKey='value'
          type='number'
          scale='sequential'
          width={0}
          domain={minMaxAxisDomain(0.8, 1.2)}
          padding={{ top: 8, bottom: 8 }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
