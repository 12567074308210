import { SVGProps } from 'react';

export const InterpolatedChartDot = (props: SVGProps<SVGElement>) => {
  const { cx, cy, onMouseEnter, onMouseLeave, color } = props;
  /** For extrapolated/intrapolated points (null points in the raw data),
   *  either cy or cx may be null depending on which axis the graph is plotted over.
   *  If these points are not handled/rendered, they shoot out of the graph boundaries (since cy/cx is null)
   *  Hence, we filter/skip rendering the active dots for these dynamically generated values
   */
  if (!cx || !cy) return <></>;

  return (
    <g>
      <circle
        cx={cx}
        cy={cy}
        r={4}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={{ stroke: color ?? '#3EC1DC' }}
        className='fill-none stroke-[2px]'
      />
      <circle cx={cx} cy={cy} r={2} className='fill-white-100' />
    </g>
  );
};
