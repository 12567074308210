import { Stack } from 'component-library';
import { useTranslation } from 'react-i18next';

import { METHODOLOGY_EXPLAINER_PAGE_CARBON_INDICATORS } from '@/config/constants';

import {
  Explainer,
  ExplainerBody,
  ExplainerContent,
  ExplainerCopy,
  ExplainerTitle,
  ExplainerTrigger,
  MethodologyLink,
} from './Explainer';

export const CarbonExplainer = () => {
  const { t } = useTranslation();

  return (
    <Explainer>
      <ExplainerTrigger data-testid='carbon-explainer'>
        {t('shared.ncaDetail.details.labels.howDoWeMeasureThis')}
      </ExplainerTrigger>
      <ExplainerContent>
        <ExplainerTitle>{t('shared.ncaDetail.details.carbon.explainers.howDoMeasureThis.headline')}</ExplainerTitle>
        <Stack className='items-start pb-6'>
          <ExplainerBody>
            <ExplainerCopy
              data-cy='carbonBelowGroundData'
              title={t('shared.ncaDetail.details.carbon.explainers.howDoMeasureThis.carbonBelowGroundTitle')}
              body={t('shared.ncaDetail.details.carbon.explainers.howDoMeasureThis.carbonBelowGroundBody')}
            />
            <ExplainerCopy
              title={t('shared.ncaDetail.details.carbon.explainers.howDoMeasureThis.emissionsTitle')}
              body={t('shared.ncaDetail.details.carbon.explainers.howDoMeasureThis.emissionsBody')}
            />
            <ExplainerCopy
              title={t('shared.ncaDetail.details.explainers.howDoMeasureThis.confidenceInterval.title')}
              body={t('shared.ncaDetail.details.explainers.howDoMeasureThis.confidenceInterval.body')}
            />
          </ExplainerBody>
          <MethodologyLink to={METHODOLOGY_EXPLAINER_PAGE_CARBON_INDICATORS} />
        </Stack>
      </ExplainerContent>
    </Explainer>
  );
};
