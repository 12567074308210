import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import { InvalidUrlError } from '@/components/invalid-url-error/InvalidUrlError';

export const withErrorBoundary = (Wrapped: () => JSX.Element) => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const ErrorBoundary = () => {
    const { reset } = useQueryErrorResetBoundary();

    return (
      <ReactErrorBoundary FallbackComponent={InvalidUrlError} onReset={reset}>
        <Wrapped />
      </ReactErrorBoundary>
    );
  };

  return ErrorBoundary;
};
