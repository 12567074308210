import { FC } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { UnexpectedMissingDataError } from '@/utils/errors/UnexpectedMissingDataError';

import { withErrorBoundary } from '../components/ErrorBoundary';

export const RequireAccessibleProject: FC = withErrorBoundary(() => {
  const { projectId } = useParams();

  if (!projectId) {
    throw new UnexpectedMissingDataError({ dataLabel: 'projectId' });
  }

  return <Outlet />;
});
