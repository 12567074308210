import { ReactComponent as Biodiversity } from '@assets/images/icons/biodiversity.svg';
import { ReactComponent as Co2 } from '@assets/images/icons/co2.svg';
import { ReactComponent as BareAndSparseVegetation } from '@assets/images/icons/landtype-bare-and-sparse-vegetation.svg';
import { ReactComponent as BuiltUpAreas } from '@assets/images/icons/landtype-built-up-areas.svg';
import { ReactComponent as Cropland } from '@assets/images/icons/landtype-cropland.svg';
import { ReactComponent as Forestry } from '@assets/images/icons/landtype-forestry.svg';
import { ReactComponent as Grassland } from '@assets/images/icons/landtype-grassland.svg';
import { ReactComponent as HerbaceousWetland } from '@assets/images/icons/landtype-herbaceous-wetland.svg';
import { ReactComponent as Mangroves } from '@assets/images/icons/landtype-mangroves.svg';
import { ReactComponent as MossAndLichens } from '@assets/images/icons/landtype-moss-and-lichens.svg';
import { ReactComponent as Peatland } from '@assets/images/icons/landtype-peatland.svg';
import { ReactComponent as PermanentWaterBodies } from '@assets/images/icons/landtype-permanent-water-bodies.svg';
import { ReactComponent as Shrubland } from '@assets/images/icons/landtype-shrubland.svg';
import { ReactComponent as SnowAndIce } from '@assets/images/icons/landtype-snow-and-ice.svg';
import { ReactComponent as TreeCover } from '@assets/images/icons/landtype-tree-cover.svg';
import { ReactComponent as Plot } from '@assets/images/icons/plot.svg';
import { ReactComponent as WaitClock } from '@assets/images/icons/waitclock.svg';
import { ReactComponent as Water } from '@assets/images/icons/water.svg';
import React, { useContext } from 'react';
import { IconContext } from 'react-icons';

import { cn } from '../utils';

export type IconProps = React.SVGAttributes<SVGElement> & {
  /**
   * Height/width of icon.
   */
  size?: React.SVGAttributes<SVGElement>['height'];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const buildIcon = (Icon: any, displayName: string) => {
  const IconComponent = ({ size: sizeProp, className: classNameProp, ...delegated }: IconProps) => {
    const context = useContext(IconContext);

    const size = sizeProp ?? context.size ?? '1em';

    const className = cn('flex-shrink-0', classNameProp);

    return <Icon height={size} width={size} className={className} {...delegated} />;
  };
  IconComponent.displayName = displayName;

  return IconComponent;
};

// NOTE: This is the only file which should import from react-icons/ri
// eslint-disable-next-line no-restricted-imports
export * from 'react-icons/ri';

export const BareAndSparseVegetationIcon = buildIcon(BareAndSparseVegetation, 'BareAndSparseVegetationIcon');
export const BiodiversityIcon = buildIcon(Biodiversity, 'BiodiversityIcon');
export const BuiltUpAreasIcon = buildIcon(BuiltUpAreas, 'BuiltUpAreasIcon');
export const Co2Icon = buildIcon(Co2, 'Co2Icon');
export const ForestryIcon = buildIcon(Forestry, 'ForestryIcon');
export const CroplandIcon = buildIcon(Cropland, 'CroplandIcon');
export const GrasslandIcon = buildIcon(Grassland, 'GrasslandIcon');
export const HerbaceousWetlandIcon = buildIcon(HerbaceousWetland, 'HerbaceousWetlandIcon');
export const MangrovesIcon = buildIcon(Mangroves, 'MangrovesIcon');
export const MossAndLichensIcon = buildIcon(MossAndLichens, 'MossAndLichensIcon');
export const PeatlandIcon = buildIcon(Peatland, 'PeatlandIcon');
export const PermanentWaterBodiesIcon = buildIcon(PermanentWaterBodies, 'PermanentWaterBodiesIcon');
export const PlotIcon = buildIcon(Plot, 'PlotIcon');
export const ShrublandIcon = buildIcon(Shrubland, 'ShrublandIcon');
export const SnowAndIceIcon = buildIcon(SnowAndIce, 'SnowAndIceIcon');
export const TreeCoverIcon = buildIcon(TreeCover, 'TreeCoverIcon');
export const WaitClockIcon = buildIcon(WaitClock, 'WaitClockIcon');
export const WaterIcon = buildIcon(Water, 'WaterIcon');
