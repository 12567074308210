import { cn, Pill, PillProps } from 'component-library';
import { useTranslation } from 'react-i18next';

import { ValueClass } from '@/api/rest/resources/types/fact';

export type ValueClassPillProps = PillProps & {
  title: ValueClass;
};

export const ValueClassPill = ({ title, className, ...delegated }: ValueClassPillProps) => {
  const { t } = useTranslation();

  return (
    <Pill
      className={cn('typography-caption rounded bg-bg-light-grey p-1 text-text-secondary', className)}
      {...delegated}
    >
      {t(`shared.ncaDetail.details.valueClassLabels.${title}`)}
    </Pill>
  );
};
