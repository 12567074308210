import { cn, Stack } from 'component-library';
import { scaleLinear } from 'd3-scale';
import React, { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Label, Text } from 'recharts-overriden';

import { Threat } from '@/api/rest/resources/conservation';
import { UnitEnum } from '@/api/rest/resources/types/units';
import { ChartHeading } from '@/components/Charts/components/ChartHeading';
import { NCCard, NCCard2SectionLayout, NCCardHeader, NCCardSectionHeader } from '@/components/NCCard/NCCard';
import { getDisplayNumber } from '@/hooks/useDisplayNumber';
import { Area } from '@/utils/area';
import { formatUnit } from '@/utils/formatting';

import { Bar, BarChart, CartesianGrid, Cell, Chart, ChartConfig, LabelList } from '../../../components/Chart';
import { useFact } from '../../../hooks/useFact';

export const DegradationDriversBreakdownCard = () => {
  const { t } = useTranslation();
  const [highlightedThreat, setHighlightedThreat] = useState<Threat | null>(null);

  return (
    <NCCard>
      <NCCardHeader title={t('shared.ncaDetail.habitatIntactness.degradationDriversBreakdown.title')} />
      <NCCard2SectionLayout className='[&>*]:py-8'>
        <ThreatsContext.Provider value={{ highlightedThreat, setHighlightedThreat }}>
          <GraphTile />
          <StatsTile />
        </ThreatsContext.Provider>
      </NCCard2SectionLayout>
    </NCCard>
  );
};

const GraphTile = () => {
  const { t } = useTranslation();

  const currentYear = useFact<number>('r1_conservation_current_year').value;

  const chartConfig = {
    built: {
      color: '#B6805A',
    },
    mine: {
      color: '#FAD9A3',
    },
    treeloss: {
      color: '#C97238',
    },
    crop: {
      color: '#E6B088',
    },
    other: {
      color: '#A27C60',
    },
  } as ChartConfig<Threat>;

  const chartData = useThreatData();

  const xAxisProps = (() => {
    const largestThreat = Math.max(...chartData.map((threat) => threat.value));

    const domain = [0, largestThreat + (20 / 100) * largestThreat];

    const scale = scaleLinear(domain, domain).nice();

    return {
      dataKey: 'value',
      domain,
      scale,
      tickFormatter: (value: number) => getDisplayNumber(value),
      ticks: [
        domain[0] as number,
        ...scale
          .ticks(5)
          .map((date) => date.valueOf())
          .slice(1),
      ],
    };
  })();

  const shouldHighlight = useHighlight();

  return (
    <Stack center spacing={6}>
      <ChartHeading
        heading={t('shared.ncaDetail.habitatIntactness.degradationDriversBreakdown.chartHeading', {
          year: currentYear,
        })}
      />
      <Chart.Container config={chartConfig} data={chartData} height={300}>
        <BarChart layout='vertical' data={chartData}>
          <CartesianGrid strokeDasharray='3 3' horizontal={false} syncWithTicks />
          <Chart.XAxis axisLine={false} {...xAxisProps} />
          <Chart.YAxis
            dataKey='name'
            type='category'
            scale='auto'
            padding={{ top: 0 }}
            axisLine={false}
            // @ts-expect-error
            tickFormatter={(v) => t(`shared.projects.project.conservation.labels.${v}`)}
            tick={(v) => (
              <Text
                {...v}
                {...(Chart.YAxis.defaultProps?.tick as Record<string, never>)}
                dy={-2}
                className={cn(
                  'transition-colors',
                  shouldHighlight(v.payload.value) ? 'fill-text-secondary' : 'fill-neutral-black-10',
                )}
              >
                {v.tickFormatter(v.payload.value)}
              </Text>
            )}
          />
          <Bar dataKey='value' barSize={16} radius={4} isAnimationActive={false}>
            {chartData.map((threat) => {
              return (
                <Cell
                  key={threat.name}
                  fill={
                    shouldHighlight(threat.name)
                      ? (chartConfig[threat.name as Threat]?.color ?? chartConfig.other.color)
                      : '#EEEEEE'
                  }
                  className='transition-[fill]'
                />
              );
            })}
            <LabelList dataKey='value' position='right' content={<BarLabel />} />
          </Bar>
        </BarChart>
      </Chart.Container>
    </Stack>
  );
};

const BarLabel = (props: React.ComponentProps<typeof Label>) => {
  // @ts-expect-error values are always of type number
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  const x = props.x + props.width + props.offset;

  // @ts-expect-error
  const y = props.y - 3.5;

  const shouldHighlight = useHighlight();

  return (
    <foreignObject className='overflow-visible' width={100} height={props.height} x={x} y={y}>
      <Chart.Label
        className={cn(
          'transition-colors',
          shouldHighlight(props.name as Threat) ? 'text-text-disabled' : 'text-neutral-black-10',
        )}
      >
        {new Area({ value: props.value as number, dimension: 'squareMeters' }).printDisplay()}
      </Chart.Label>
    </foreignObject>
  );
};

const useHighlight = () => {
  const { highlightedThreat } = useContext(ThreatsContext);

  return (threat: Threat) => {
    if (highlightedThreat == null) {
      return true;
    }

    return threat === highlightedThreat;
  };
};

const StatsTile = () => {
  const { t } = useTranslation();

  const { setHighlightedThreat } = useContext(ThreatsContext);

  const currentYear = useFact<number>('r1_conservation_current_year').value;
  const baselineYear = useFact<number>('r1_conservation_baseline_year').value;

  const tableData = useThreatData();

  return (
    <Stack spacing={10} className='px-6'>
      <Stack spacing={6}>
        <NCCardSectionHeader title={t('shared.ncaDetail.habitatIntactness.degradationDriversBreakdown.statsHeading')} />

        <div className='w-full overflow-x-scroll'>
          <table
            className={cn(
              'typography-body2 w-full table-auto border-collapse overflow-hidden rounded-3xl bg-[#fbfbfb]',
            )}
          >
            <thead>
              <tr className='border-b border-[#e0e0e0] [&>*]:typography-overline [&>*]:p-6 [&>*]:pb-4 [&>*]:text-text-secondary '>
                <th className='text-start'>
                  {t('shared.ncaDetail.habitatIntactness.degradationDriversBreakdown.identifiedThreats')}
                </th>
                <th className='text-end'>{currentYear}</th>
                <th className='text-end'>{baselineYear}</th>
                <th className='text-end'>
                  {t('shared.ncaDetail.habitatIntactness.degradationDriversBreakdown.change')}
                </th>
              </tr>
            </thead>
            <tbody
              onMouseLeave={() => setHighlightedThreat(null)}
              className='[&>*:not(:last-child)]:border-b [&>*:not(:last-child)]:border-[#e0e0e0]'
            >
              {tableData.map((threat) => {
                const latestValue = threat.ts[`${currentYear}` as string] as number;
                const baselineValue = threat.ts[`${baselineYear}` as string] as number;

                return (
                  <tr
                    key={threat.name}
                    onMouseOver={() => setHighlightedThreat(threat.name)}
                    onFocus={() => setHighlightedThreat(threat.name)}
                    onMouseLeave={() => setHighlightedThreat(null)}
                    onBlur={() => setHighlightedThreat(null)}
                    className='text-text-primary [&>*]:typography-body2 hover:bg-neutral-black-4 [&>*]:px-6 [&>*]:py-2'
                  >
                    <td>{t(`shared.projects.project.conservation.labels.${threat.name}`)}</td>
                    <td className='text-end'>
                      {new Area({
                        value: latestValue,
                        dimension: 'squareMeters',
                      }).renderDisplay({ className: 'whitespace-nowrap' })}
                    </td>
                    <td className='text-end'>
                      {new Area({
                        value: baselineValue,
                        dimension: 'squareMeters',
                      }).renderDisplay({ className: 'whitespace-nowrap' })}
                    </td>
                    <PercentChangeCell baseline={baselineValue} latest={latestValue} />
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Stack>
    </Stack>
  );
};

const PercentChangeCell = ({ baseline, latest }: { baseline: number; latest: number }) => {
  const percentChange = ((baseline - latest) / latest) * 100;

  return (
    <td
      className={cn('whitespace-nowrap text-end', {
        'text-error': percentChange > 0,
        'text-secondary-100': percentChange < 0,
      })}
    >
      {getDisplayNumber(percentChange, undefined, { signDisplay: 'exceptZero' })}
      {formatUnit(UnitEnum['%'])}
    </td>
  );
};

const useThreatData = () => {
  return [
    {
      name: 'built',
      value: useFact<number>('r1_conservation_built').value,
      ts: useFact<Record<string, number>>('r1_conservation_built_ts').value,
    },
    {
      name: 'crop',
      value: useFact<number>('r1_conservation_crop').value,
      ts: useFact<Record<string, number>>('r1_conservation_crop_ts').value,
    },
    {
      name: 'mine',
      value: useFact<number>('r1_conservation_mine').value,
      ts: useFact<Record<string, number>>('r1_conservation_mine_ts').value,
    },
    {
      name: 'road',
      value: useFact<number>('r1_conservation_road').value,
      ts: useFact<Record<string, number>>('r1_conservation_road_ts').value,
    },
    {
      name: 'treeloss',
      value: useFact<number>('r1_conservation_treeloss').value,
      ts: useFact<Record<string, number>>('r1_conservation_treeloss_ts').value,
    },
  ]
    .filter(({ value }) => value > 0)
    .sort((a, b) => b.value - a.value) as { name: Threat; value: number; ts: Record<string, number> }[];
};

const ThreatsContext = createContext<{
  highlightedThreat: Threat | null;
  setHighlightedThreat: (t: Threat | null) => void;
}>({ highlightedThreat: null, setHighlightedThreat: () => undefined });
