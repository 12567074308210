import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';
import { hasProjectTypeImpactProjections } from '@/pages/shared/hooks/utils';
import { paths } from '@/routing';

export const RequireProjectableProject: FC = () => {
  const project = useProjectDetailById().data;

  const isProjectTypeProjectable = hasProjectTypeImpactProjections(project);

  if (!isProjectTypeProjectable) {
    return <Navigate to={paths.invalidUrl} replace />;
  }

  return <Outlet />;
};
