import { useTranslation } from 'react-i18next';

import { NCCard, NCCard2SectionLayout, NCCardComplianceFooter, NCCardHeader } from '@/components/NCCard/NCCard';
import { CanopyHeightExplainer } from '@/components/NCExplainers/CanopyHeightExplainer';
import { COMPLIANCE_LOGOS_CANOPY_HEIGHT } from '@/config/constants';

import { CanopyHeightGraphTile } from './GraphTile';
import { CanopyHeightCardStatsTile } from './StatsTile';

export const CanopyHeight = () => {
  const { t } = useTranslation();

  return (
    <NCCard explainer={<CanopyHeightExplainer />}>
      <NCCardHeader title={t('shared.ncaDetail.details.forest.labels.canopyHeight')} />
      <NCCard2SectionLayout>
        <CanopyHeightGraphTile />
        <CanopyHeightCardStatsTile />
      </NCCard2SectionLayout>
      <NCCardComplianceFooter logos={COMPLIANCE_LOGOS_CANOPY_HEIGHT} />
    </NCCard>
  );
};
