import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { METHODOLOGY_EXPLAINER_PAGE_BU } from '@/config/constants';

import { ChartLegendBarItem } from '../components/types';

type UseLegendItemsProps = {
  estimatedDataColor: string;
};

export const useEstimatedDataLegendItem = ({ estimatedDataColor }: UseLegendItemsProps) => {
  const { t } = useTranslation();

  return useMemo(() => {
    return [
      {
        label: t('shared.ncaDetail.details.explainers.estimatedData.title'),
        color: estimatedDataColor,
        shape: (
          <span
            className='h-2 w-2 rounded-full border-2 bg-white-100'
            style={{ borderColor: estimatedDataColor }}
          ></span>
        ),
        popoverContent: {
          title: t('shared.ncaDetail.details.explainers.estimatedData.title'),
          body: (
            <Trans
              i18nKey='shared.ncaDetail.details.explainers.estimatedData.body'
              components={{
                a: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    href={METHODOLOGY_EXPLAINER_PAGE_BU}
                    target='_blank'
                    className='text-primary-100 underline'
                    rel='noreferrer'
                  />
                ),
              }}
            />
          ),
        },
      },
    ] as ChartLegendBarItem[];
  }, [t, estimatedDataColor]);
};
