import { Stack } from 'component-library';
import { useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { CanopyHeightHistoricalChart } from '@/components/Charts';
import { ChartHeading } from '@/components/Charts/components/ChartHeading';
import { GraphFact } from '@/components/Charts/types';
import { usePlotFact } from '@/pages/shared/hooks/usePlotFact';

import { NoPlotData } from '../NoPlotData';

export const CanopyHeightGraphTile = () => {
  const { t } = useTranslation();

  const canopyHeightHistoric = usePlotFact<GraphFact[]>(R1FactType.r1_soil_carbon_historic_total_graph); // TODO: MVP-4772 - change with forest fact
  const chartData = canopyHeightHistoric?.value;

  const noChartData = !chartData?.length;
  if (noChartData) {
    return <NoPlotData />;
  }

  return (
    <Stack center data-testid='canopy-height-graph-tile'>
      <Stack spacing={2.5} className='w-full items-center justify-center px-1 py-6 lg:items-start lg:p-8 lg:pl-1'>
        <ChartHeading
          heading={t('shared.ncaDetail.details.forest.labels.historicalCanopyHeightGraphHeadline')}
          popover={{
            title: t('shared.ncaDetail.details.forest.explainers.historicalCanopyHeight.title'),
            body: t('shared.ncaDetail.details.forest.explainers.historicalCanopyHeight.body'),
          }}
          className='w-full px-6 sm:px-0'
        />
        <CanopyHeightHistoricalChart data={chartData} showTooltip={true} data-testid='canopy-height-historic-chart' />
      </Stack>
    </Stack>
  );
};
