import { SVGProps } from 'react';

import { PlotReportFactLabel } from '@/api/rest/resources/types/fact';

import { InterpolatedChartDot } from '../InterpolatedChartDot';

type PayloadType = {
  [key: string]: {
    hasInterpolatedLabels?: boolean;
    labels?: PlotReportFactLabel[];
  };
};

export const CustomEstimationDot = (props: SVGProps<SVGElement> & { dataKey: string; payload: PayloadType }) => {
  const factName = props.dataKey?.replace('.value', '');
  // eslint-disable-next-line security/detect-object-injection
  const isInterlopated = props.payload?.[factName]?.hasInterpolatedLabels;

  if (!isInterlopated) return <> </>;
  return <InterpolatedChartDot {...props} color={props.color} />;
};
