import { useParams } from 'react-router-dom';

import { PlotReportFactLabelFilter, R1FactType } from '@/api/rest/resources/types/fact';

import { usePlotReportForPlot } from './usePlotReportForPlot';
import { filterValidLabels, filterValidLabelsForScenario, getFact } from './utils';

/**
 * @param plotIdArg Falls back to the plotId in the URL if no value is provided.
 */
export const usePlotFact = <T = unknown>(
  type: R1FactType,
  plotIdArg?: string | null,
  labelFilters?: PlotReportFactLabelFilter,
) => {
  const routeParams = useParams();
  const plotId = plotIdArg ?? routeParams.plotId;

  if (!plotId) {
    throw new Error('Missing URL param: plotId');
  }

  // Since BE ignore label filters for benchmark and landuse, we filter those out before hitting the API to avoid multiple duplicate calls
  const plotReportFactLabelFilters = labelFilters ? filterValidLabelsForScenario(labelFilters) : {};
  const plotReport = usePlotReportForPlot(plotId, plotReportFactLabelFilters).data;

  // But we pass all filters to getFact to fetch the right fact from the API response (eg: choose between high/low/mod benchmark facts)
  const labels = labelFilters ? filterValidLabels(labelFilters) : {};
  return getFact<T>(plotReport, type, labels);
};
