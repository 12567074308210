import i18next from 'i18next';

const MAX_TIMESTAMP = 8640000000000000;

export const MIN_ES_DATE = new Date(-MAX_TIMESTAMP);
export const MAX_ES_DATE = new Date(MAX_TIMESTAMP);

export type DateLike = ConstructorParameters<typeof Date>[0];

export const isValidDateInput = (input: unknown): input is DateLike => {
  /**
   * null and 0 are valid inputs to the
   * Date constructor that resolve to 1/1/1970
   */
  if (!input) return false;

  // @ts-expect-error Date does not throw regardless of input
  const date = new Date(input);
  return date instanceof Date && !Number.isNaN(date.valueOf());
};

export const areValidDateInputs = (input: unknown[]): input is DateLike[] => {
  const containsInvalidDate = input.some((d) => !isValidDateInput(d));
  return !containsInvalidDate;
};

export const printYear = (input: DateLike) => {
  return new Date(input).toLocaleString(i18next.language, { year: 'numeric' });
};
export const printMonthYear = (input: DateLike) => {
  return new Date(input).toLocaleString(i18next.language, { month: 'numeric', year: 'numeric' });
};

export const printShortMonth = (input: DateLike) => {
  return new Date(input).toLocaleString(i18next.language, { month: 'short' });
};

export const printDDMMYYYYHHMM = (input: unknown) => {
  if (!isValidDateInput(input)) {
    return undefined;
  }
  return new Date(input).toLocaleString(i18next.language, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const getLatestDate = (dates: unknown[]) => {
  if (!areValidDateInputs(dates)) {
    return undefined;
  }

  let latestDate: Date | undefined;

  dates.forEach((date) => {
    const currentDate = new Date(date);
    if (!latestDate) {
      latestDate = currentDate;
      return;
    }

    if (currentDate > latestDate) {
      latestDate = currentDate;
    }
  });

  return latestDate;
};
