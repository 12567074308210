import { ColumnDef } from '@tanstack/react-table';
import { ForestryIcon, Stack } from 'component-library';
import { useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { UnitEnum } from '@/api/rest/resources/types/units';

import { LandMonitoringPageWrapper } from '../../components/LandMonitoring.page-wrapper';
import { FactCell, PlotRow } from '../../components/plot-list/Cells';
import { PlotListDesktop } from '../../components/plot-list/PlotListDesktop';
import { PlotListTitle } from '../../components/plot-list/PlotListTitle';
import { ToggleSortButton } from '../../components/plot-list/ToggleSortButton';
import { CanopyCover } from './components/canopyCover/CanopyCover';
import { CanopyHeight } from './components/canopyHeight/CanopyHeight';

export const ForestStructureDetailPage = () => {
  const { t } = useTranslation();

  return (
    <LandMonitoringPageWrapper title={t('shared.ncaDetail.details.forest.labels.forestStructure')}>
      <Stack spacing={8}>
        <ForestStructure />
        <ForestStructurePlotList />
      </Stack>
    </LandMonitoringPageWrapper>
  );
};

export const ForestStructure = () => {
  return (
    <>
      <CanopyHeight />
      <CanopyCover />
    </>
  );
};

export const ForestStructurePlotList = () => {
  const { t } = useTranslation();

  const customPlotListColumns: ColumnDef<PlotRow>[] = [
    {
      id: 'canopyHeight',
      accessorKey: 'canopyHeight',
      header: ({ column }) => (
        <ToggleSortButton column={column} className='-ml-2.5'>
          {t('shared.ncaDetail.details.forest.labels.canopyHeight')}
        </ToggleSortButton>
      ),
      cell: ({ row }) => {
        return (
          <FactCell
            plot={row.original}
            // TODO: MVP-4772 - change with forest fact
            factType={R1FactType.r1_carbon_bg_latest_analysis_total}
            unitType={UnitEnum.m}
            icon={ForestryIcon}
          />
        );
      },
    },
    {
      id: 'canopyCover',
      accessorKey: 'canopyCover',
      header: ({ column }) => (
        <ToggleSortButton column={column} className='-ml-2.5'>
          {t('shared.ncaDetail.details.forest.labels.canopyCover')}
        </ToggleSortButton>
      ),
      cell: ({ row }) => {
        return (
          <FactCell
            plot={row.original}
            // TODO: MVP-4772 - change with forest fact
            factType={R1FactType.r1_carbon_bg_previous_1y_uplift_total_pct_change}
            unitType={UnitEnum['%']}
            icon={ForestryIcon}
          />
        );
      },
    },
  ];

  return (
    <div>
      <PlotListTitle title={t('shared.ncaDetail.details.forest.labels.forestStructureDataPerPlot')} />
      <PlotListDesktop customColumns={customPlotListColumns} />
    </div>
  );
};
