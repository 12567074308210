import { RiCompass4Line, Stack } from 'component-library';
import { Trans, useTranslation } from 'react-i18next';

import { Capsule, CapsuleContent, CapsuleIcon, CapsuleLabel } from '@/components';
import { ChartHeading } from '@/components/Charts/components/ChartHeading';
import {
  ExplainerWell,
  NCCard,
  NCCard2SectionLayout,
  NCCardHeader,
  NCCardSectionHeader,
} from '@/components/NCCard/NCCard';

import { IndicatorSpeciesBreakdownChart } from '../../../components/IndicatorSpeciesBreakdownChart';
import { useFact } from '../../../hooks/useFact';
import { useLatestAnalysisDatestamp } from '../../../hooks/useLatestAnalysisDatestamp';

export const SpeciesBreakdownCard = () => {
  const { t } = useTranslation();
  return (
    <NCCard>
      <NCCardHeader title={t('shared.ncaDetail.floraAndFauna.speciesBreakdown.title')} />

      <NCCard2SectionLayout className='[&>*]:py-8'>
        <GraphTile />
        <StatsTile />
      </NCCard2SectionLayout>
    </NCCard>
  );
};

const GraphTile = () => {
  const { t } = useTranslation();

  return (
    <Stack center spacing={6} className='z-[1]'>
      <ChartHeading
        heading={t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.chartHeading')}
        className='w-full px-6 sm:px-0'
      />
      <IndicatorSpeciesBreakdownChart />
    </Stack>
  );
};

const StatsTile = () => {
  const { t } = useTranslation();

  const detectedSpeciesCount = useFact('r1_conservation_groups_detected');
  const detectedSpeciesTotal = useFact('r1_conservation_groups_total');

  const latestAnalysisDatestamp = useLatestAnalysisDatestamp();

  return (
    <Stack spacing={10} className='px-6'>
      <Stack spacing={6}>
        <NCCardSectionHeader
          title={t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.statsHeading')}
          popover={{
            title: t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.explainers.floraAndFauna.title'),
            body: t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.explainers.floraAndFauna.body'),
          }}
          supplementaryInfo={latestAnalysisDatestamp}
        />
        <Stack spacing={16} className='flex-wrap md:flex-row'>
          <Capsule
            label={
              <CapsuleLabel
                infoPopoverProps={{
                  title: t(
                    'shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.explainers.indicatorGroupsDetected.title',
                  ),
                  body: t(
                    'shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.explainers.indicatorGroupsDetected.body',
                  ),
                }}
              >
                {t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.indicatorGroupsDetected')}
              </CapsuleLabel>
            }
            content={
              <CapsuleContent>
                {t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.detectedSpeciesDisplay', {
                  detectedCount: detectedSpeciesCount.value,
                  totalCount: detectedSpeciesTotal.value,
                })}
              </CapsuleContent>
            }
            thumbnail={<CapsuleIcon icon={<RiCompass4Line />} variant='default' />}
          />
        </Stack>
      </Stack>

      <ExplainerWell
        title={t('global.whatDoesThisMean')}
        body={
          <Trans
            i18nKey='shared.ncaDetail.floraAndFauna.speciesBreakdown.explanation'
            values={{ detectedCount: detectedSpeciesCount.value, totalCount: detectedSpeciesTotal.value }}
            components={{
              ul: <ul className='ml-6 list-disc' />,
              li: <li />,
            }}
          />
        }
      />
    </Stack>
  );
};
