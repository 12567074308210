import { Stack } from 'component-library';
import { FC, HTMLAttributes, SVGProps } from 'react';
import { useTranslation } from 'react-i18next';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { PlotReportFactLabel, PlotReportFactLabelNameEnum, ValueClass } from '@/api/rest/resources/types/fact';
import { UnitEnum } from '@/api/rest/resources/types/units';
import { getDisplayNumber } from '@/hooks/useDisplayNumber';
import { useScreenSize } from '@/hooks/useScreenSize';
import { Logger } from '@/lib/logs/logger';
import { colorPalette } from '@/theme/colorPalette';
import { formatUnit } from '@/utils/formatting';
import { printYear } from '@/utils/formatting/date';

import { ValueClassPill } from '../Pill/ValueClassPill';
import { Bar, BarChart, Chart, ErrorBar, getXAxisProps, TooltipProps } from './components/Chart';
import { ChartLegendBar } from './components/LegendBar';
import {
  ChartTooltip,
  ChartTooltipConfidenceInterval,
  ChartTooltipDot,
  ChartTooltipTextPrimary,
  ChartTooltipTextSecondary,
} from './components/Tooltip';
import { ChartLegendBarItem } from './components/types';
import { NoChartData } from './NoChartData';
import { ChartProps } from './types';
import { getChartDataFromGraphFact, getXAxisDomainForHistoricGraph, minMaxAxisDomain, sortChartData } from './utils';

const CANOPY_HEIGHT_LABEL = 'global.analysis.canopyHeight';
const UNIT = formatUnit(UnitEnum.m);
const RECTANGLE_RADIUS = 4;

export const chartColors = {
  canopyHeight: {
    stroke: '#65D365',
    fill: '#9CD892',
  },
  confidenceInterval: {
    fill: '#2A645233',
  },
} as const;

const factNames = {
  // TODO: MVP-4772 - change with forest fact
  permanent_soil_carbon_total: 'canopyHeight',
};

export const CanopyHeightHistoricalChart: FC<HTMLAttributes<HTMLDivElement> & ChartProps> = ({
  data,
  showTooltip,
  ...delegated
}) => {
  const { t } = useTranslation();
  const isSmallScreen = useScreenSize() === 'small';

  const dateDataMap = getChartDataFromGraphFact(data, factNames); // TODO: MVP-4772 - check if this function needs a formatter when a real forest fact is used
  let sortedChartData = sortChartData(dateDataMap, (date) => new Date(date).getTime());

  const firstTick = sortedChartData.at(0);
  const lastTick = sortedChartData.at(-1);

  if (!firstTick || !lastTick) {
    Logger.error('Not enough data to render chart');
    return (
      <div className='relative flex h-full w-full flex-col justify-center' {...delegated}>
        <NoChartData />
      </div>
    );
  }

  sortedChartData = getDataWithCIsAsErrorValues(sortedChartData as CanopyHeightData[]);

  const xTicksDomain = getXAxisDomainForHistoricGraph(firstTick.label, lastTick.label);

  const xAxisProps = {
    ...getXAxisProps(xTicksDomain),
    tickFormatter: printYear,
  };

  return (
    <>
      <Chart.Container
        {...delegated}
        data={sortedChartData}
        config={{
          canopyHeight: {
            label: t(CANOPY_HEIGHT_LABEL),
            color: chartColors.canopyHeight.stroke,
          },
        }}
      >
        <BarChart data={sortedChartData}>
          <defs>
            <linearGradient id='canopyHeight' x1='0' y1='-2.6' x2='0' y2='1'>
              <stop offset='0%' stopColor={chartColors.canopyHeight.fill} stopOpacity={0} />
              <stop offset='100%' stopColor={chartColors.canopyHeight.fill} stopOpacity={1} />
            </linearGradient>
          </defs>
          <Bar
            isAnimationActive={false}
            fill='url(#canopyHeight)'
            dataKey='canopyHeight.value'
            radius={[RECTANGLE_RADIUS, RECTANGLE_RADIUS, 0, 0]}
            activeBar={<CustomActiveBar />}
            className='canopy-height-bar'
          >
            <ErrorBar
              dataKey='canopyHeight.errorBars'
              width={isSmallScreen ? 6 : 12}
              strokeWidth={2.5}
              stroke={chartColors.confidenceInterval.fill}
              className='canopy-height-error-bar'
            />
          </Bar>
          <Chart.XAxis {...xAxisProps} padding={{ left: 40 }} className='canopy-height-x-axis' />
          <Chart.YAxis domain={minMaxAxisDomain(0.8, 1.2)} className='canopy-height-y-axis'>
            <Chart.Label>{UNIT}</Chart.Label>
          </Chart.YAxis>
          {showTooltip && <Chart.Tooltip content={<CustomTooltip />} cursor={false} />}
        </BarChart>
      </Chart.Container>
      <CustomLegendBar />
    </>
  );
};

type CanopyHeightData = {
  label: string | number | Date;
  canopyHeight: {
    value: number;
    confidence_interval: [number, number];
  };
};

const getDataWithCIsAsErrorValues = (chartData: CanopyHeightData[]) => {
  return chartData.map((data) => {
    const { canopyHeight } = data;

    if (!canopyHeight?.confidence_interval || canopyHeight.confidence_interval.every((ci) => !ci)) {
      return data;
    }

    const lowerError = canopyHeight.confidence_interval[0]
      ? canopyHeight.value - canopyHeight.confidence_interval[0]
      : 0;
    const upperError = canopyHeight.confidence_interval[1]
      ? canopyHeight.confidence_interval[1] - canopyHeight.value
      : 0;

    return {
      ...data,
      canopyHeight: {
        ...data.canopyHeight,
        errorBars: [lowerError, upperError],
      },
    };
  });
};

const CustomLegendBar = () => {
  const { t } = useTranslation();

  const legendBarItems: ChartLegendBarItem[] = [
    {
      label: t(CANOPY_HEIGHT_LABEL),
      color: chartColors.canopyHeight.fill,
    },
    {
      label: t('shared.ncaDetail.details.explainers.confidenceInterval.title'),
      color: chartColors.confidenceInterval.fill,
      shape: (
        <span
          className='h-[2px] w-[14px]'
          style={{
            backgroundColor: chartColors.confidenceInterval.fill,
          }}
        />
      ),
      popoverContent: {
        title: t('shared.ncaDetail.details.explainers.confidenceInterval.title'),
        body: t('shared.ncaDetail.details.explainers.confidenceInterval.body'),
      },
    },
  ];

  return (
    <ChartLegendBar items={legendBarItems} className='mt-8 sm:ml-[28px]' data-testid='canopy-height-chart-legend' />
  );
};

const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
  const { t } = useTranslation();

  const canopyHeightValue = payload?.find((data) => data.dataKey === 'canopyHeight.value');
  const canopyHeightDisplay =
    canopyHeightValue?.value != null
      ? `${getDisplayNumber(canopyHeightValue?.value as string, window.navigator.language)} ${UNIT}`
      : t('global.analysis.noData');

  const dataPointHasLabel = canopyHeightValue && canopyHeightValue?.payload?.canopyHeight?.labels?.length > 0;
  const dataPointValueClass = dataPointHasLabel
    ? canopyHeightValue.payload.canopyHeight.labels.find(
        (dataLabel: PlotReportFactLabel) => dataLabel.name === PlotReportFactLabelNameEnum.value_class,
      )
    : undefined;
  const dataLabel = dataPointValueClass?.value as ValueClass;

  if (!active) return null;

  return (
    <ChartTooltip>
      <Stack spacing={8} direction='row' className='justify-between'>
        <ChartTooltipTextSecondary>{printYear(label)}</ChartTooltipTextSecondary>
        {dataLabel && <ValueClassPill title={dataLabel} />}
      </Stack>
      <Stack spacing={8} direction='row'>
        <ChartTooltipTextPrimary>
          {t('shared.ncaDetail.details.forest.labels.averageCanopyHeight')}
        </ChartTooltipTextPrimary>
        <ChartTooltipTextPrimary>{canopyHeightDisplay}</ChartTooltipTextPrimary>
      </Stack>
      <ChartTooltipConfidenceInterval ciPct={canopyHeightValue?.payload?.canopyHeight?.confidence_interval_pct} />
    </ChartTooltip>
  );
};

const CustomActiveBar = (props: SVGProps<SVGElement>) => {
  const { x, y, width, height } = props;

  /** We skip rendering invalid points (points that might have x/y/height/width null)
   * these points should ideally not occur but might occur when extrapolated/interpolated/connectNulls, etc dynamically generated by recharts
   * If they are not handled, they will shoot out of the graph boundaries (due to unexpected behaviour of undefined/coercing to 0)
   * we only want to render bars that have a valid non-null height/width/x/y
   */
  if (!x || !y || !width || !height) return <></>;

  const barMiddleX = (x as number) + (width as number) / 2;
  const barBottom = (y as number) + (height as number);

  return (
    <g>
      <rect x={x} y={y} width={width} height={height} ry={RECTANGLE_RADIUS} fill={chartColors.canopyHeight.fill} />
      <line x1={barMiddleX} x2={barMiddleX} y1={0} y2={barBottom} stroke={colorPalette.divider} strokeWidth={1} />
      <ChartTooltipDot cx={barMiddleX} cy={y} />
    </g>
  );
};

export { CustomTooltip as CustomTooltipCanopyHeightHistorical };
