import { PlotReport } from '@/api/rest/resources/types/dashboard';
import {
  PlotReportFactElement,
  PlotReportFactLabel,
  PlotReportFactLabelFilter,
  PlotReportFactLabelNameEnum,
  R1FactType,
} from '@/api/rest/resources/types/fact';
import { ProjectDetail, ProjectReport } from '@/api/rest/resources/types/project';
import { PROJECT_TYPES_WITH_IMPACT_PROJECTIONS } from '@/config/constants';

export const hasLabel = (filterLabel: PlotReportFactLabelFilter, labels: PlotReportFactLabel[]) => {
  return Object.entries(filterLabel).every(([key, value]) =>
    labels.some((label) => label.name === key && label.value === value),
  );
};

export const filterValidLabels = (labels: PlotReportFactLabelFilter) => {
  const allowedLabelFilters = Object.keys(PlotReportFactLabelNameEnum);
  return Object.fromEntries(
    Object.keys(labels)
      .filter((filter) => allowedLabelFilters.includes(filter))
      .map((filter) => [filter, labels[filter as keyof typeof PlotReportFactLabelNameEnum]]),
  );
};

// PlotReportFactLabels that are used to filter/update scenarios
enum ScenarioPlotReportFactLabelNameEnum {
  carbon_input = PlotReportFactLabelNameEnum.carbon_input,
  tillage = PlotReportFactLabelNameEnum.tillage,
  land_use = PlotReportFactLabelNameEnum.land_use,
}
export const filterValidLabelsForScenario = (labels: PlotReportFactLabelFilter) => {
  const allowedLabelFilters = Object.keys(ScenarioPlotReportFactLabelNameEnum);
  return Object.fromEntries(
    Object.keys(labels)
      .filter((filter) => allowedLabelFilters.includes(filter))
      .map((filter) => [filter, labels[filter as keyof typeof ScenarioPlotReportFactLabelNameEnum]]),
  );
};

export const getFact = <T = unknown>(
  plotReport: ProjectReport | PlotReport,
  factType: R1FactType,
  factLabelFilters?: PlotReportFactLabelFilter,
) => {
  if (!Object.keys(R1FactType).includes(factType)) {
    throw Error(`Unrecognised type received: ${factType}`);
  }

  return plotReport.facts.find(
    (f) => f.name === factType && (factLabelFilters && f.labels ? hasLabel(factLabelFilters, f.labels) : true),
  ) as PlotReportFactElement<T | null> | undefined;
};

export const hasProjectTypeImpactProjections = (project: Pick<ProjectDetail, 'landtypes_allowed'>) => {
  return project.landtypes_allowed.some((landType) => PROJECT_TYPES_WITH_IMPACT_PROJECTIONS.includes(landType));
};
