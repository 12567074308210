import { Fragment } from 'react';
import { Link, LinkProps, To } from 'react-router-dom';

export const MaybeLink = ({ to, children, ...delegated }: Omit<LinkProps, 'to'> & { to?: To | null }) => {
  if (to)
    return (
      <Link to={to} {...delegated}>
        {children}
      </Link>
    );

  return <Fragment>{children}</Fragment>;
};
