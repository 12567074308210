import { ColumnDef } from '@tanstack/react-table';
import { Loader, Stack } from 'component-library';
import { useTranslation } from 'react-i18next';

import { SuspenseBoundary } from '@/components';
import { BiodiversityExplainer } from '@/components/NCExplainers/BiodiversityExplainer';
import { useScreenSize } from '@/hooks/useScreenSize';

import { LandMonitoringPageWrapper } from '../../components/LandMonitoring.page-wrapper';
import { BiodiversityZoneCell, PlotRow } from '../../components/plot-list/Cells';
import { PlotListDesktop } from '../../components/plot-list/PlotListDesktop';
import { PlotListTitle } from '../../components/plot-list/PlotListTitle';
import { ToggleSortButton } from '../../components/plot-list/ToggleSortButton';
import { BiodiversityCard } from './components/biodiversity/Card';

export const BiodiversityDetailPage = () => {
  const { t } = useTranslation();
  const isSmallScreen = useScreenSize() === 'small';

  const customPlotListColumns: ColumnDef<PlotRow>[] = [
    {
      id: 'biodiversityZone',
      accessorKey: 'biodiversityZone',
      header: ({ column }) => (
        <ToggleSortButton column={column} className='-ml-2.5'>
          {t('global.analysis.protectedHabitat')}
        </ToggleSortButton>
      ),
      cell: BiodiversityZoneCell,
    },
  ];

  return (
    <LandMonitoringPageWrapper title={t('shared.ncaDetail.details.biodiversity.labels.biodiversity')}>
      <Stack spacing={8}>
        <BiodiversityCard />
        <SuspenseBoundary
          fallback={
            <Stack center className='h-[600px]'>
              <Loader />
            </Stack>
          }
        >
          <div>
            <PlotListTitle
              title={t('shared.ncaDetail.details.biodiversity.labels.biodiversityDataPerPlot')}
              action={!isSmallScreen ? <BiodiversityExplainer /> : null}
            />
            <PlotListDesktop customColumns={customPlotListColumns} />
          </div>
        </SuspenseBoundary>
      </Stack>
    </LandMonitoringPageWrapper>
  );
};
