import { Divider, Stack, StackProps } from 'component-library';
import { useTranslation } from 'react-i18next';

import { PlotType } from '@/api/rest/resources/types/plot';
import { useProject } from '@/pages/landsteward/hooks/useProject';

import { AboveGroundCarbonBalance } from './components/AboveGroundCarbonBalance';
import { BelowGroundCarbonBalance } from './components/BelowGroundCarbonBalance';
import { BiodiversityBalance } from './components/BiodiversityBalance';
import { WaterBalance } from './components/WaterBalance';

export const NCAPage = () => {
  const { t } = useTranslation();

  const project = useProject().data;
  // TODO: MVP-4724 - swap out with PlotType.FORESTRY
  const isForestProject = project.landtypes_allowed.includes(PlotType.TREE_COVER);
  const CarbonBalance = isForestProject ? AboveGroundCarbonBalance : BelowGroundCarbonBalance;

  return (
    <Stack spacing={10}>
      <span className='typography-overline'>{t('shared.projects.labels.naturalCapitalAccount')}</span>
      <div
        className={`grid grid-cols-1 gap-10 rounded-3xl bg-white-100 px-8 py-8 ${
          isForestProject ? 'lg:grid-cols-2' : 'lg:grid-cols-3'
        }`}
      >
        <BalanceCard title={t('global.analysis.carbon')}>
          <CarbonBalance />
        </BalanceCard>
        <BalanceCard title={t('global.analysis.water')} showDivider={!isForestProject}>
          <WaterBalance />
        </BalanceCard>
        {!isForestProject && (
          <BalanceCard title={t('global.analysis.biodiversity')} showDivider={false}>
            <BiodiversityBalance />
          </BalanceCard>
        )}
      </div>
    </Stack>
  );
};

type BalanceCardProps = StackProps & {
  title: string;
  showDivider?: boolean;
};

const BalanceCard = ({ title, className, children, showDivider = true, ...delegated }: BalanceCardProps) => {
  return (
    <Stack className={className} {...delegated}>
      <span className='typography-h3 mb-8'>{title}</span>
      <div className='flex flex-col justify-between gap-10 lg:flex-row'>
        {children}
        {showDivider && (
          <>
            <Divider direction='vertical' className='hidden border border-dashed lg:block' />
            <Divider direction='horizontal' className='border border-dashed lg:hidden' />
          </>
        )}
      </div>
    </Stack>
  );
};
