import { PlotStatusEnum, PlotType } from '@/api/rest/resources/types/plot';
import { ProjectStatus } from '@/api/rest/resources/types/project';

export const APP_VERSION = import.meta.env.APP_VERSION as string; // passed from Docker
export const REST_API_URL = import.meta.env.REST_API_URL as string;
export const SHOW_APPROXIMATE_PLOTS_AS_PINS = (import.meta.env.SHOW_APPROXIMATE_PLOTS_AS_PINS as string) !== 'false';

export const REST_API_VERSION = 'v1';
export const MAPBOX_TOKEN =
  'pk.eyJ1Ijoicm9ubnktcmVudG5lciIsImEiOiJjbDRqZHhscTUwbDU4M2J1YnpnOWwxYTJ5In0.wIjndwWC0pPZ6YDX8qzK7Q';
export const MAPBOX_NO_LABELS = {
  token: 'pk.eyJ1IjoiZ2FyZXRoLXByaWVkZSIsImEiOiJjbG1yYnEwejIwNWxkMmtxbWR6ZHp2YXN5In0.tzkWc325BHZefvU1hrukmw',
  ownerId: 'gareth-priede',
  styleId: 'clmu660l102fj01qu29f822xb',
};

export const DEFAULT_MAP_SEARCH_ZOOM = 16;
export const DEFAULT_MAP_LOCATION = {
  longitude: 11.6898361,
  latitude: 49.6889203,
  zoom: 2,
};
export const DEFAULT_MAP_STYLE = 'mapbox://styles/mapbox/satellite-streets-v12';
export const COOKIE_IDENTIFIER_OF_COOKIE_BANNER = 'cookie_consent';
export const GA_TRACKING_ID = import.meta.env.GA_TRACKING_ID as string;
export const DEFAULT_DELAY_TIMER = 1000;
export const LANDING_PAGE_URL = 'https://www.thelandbankinggroup.com';
export const METHODOLOGY_EXPLAINER_PAGE_BU = 'https://thelandbankinggroup.notion.site';
export const METHODOLOGY_EXPLAINER_PAGE_VNU = 'https://www.africanparks.org/sites/default/files/More%20on%20VNU.pdf';
export const METHODOLOGY_EXPLAINER_PAGE_NCI = 'https://science.landler.io/natural-capital-indicators';
export const METHODOLOGY_EXPLAINER_PAGE_WATER_INDICATORS =
  'https://science.landler.io/natural-capital-indicators/water-indicators';
export const METHODOLOGY_EXPLAINER_PAGE_CARBON_INDICATORS =
  'https://science.landler.io/natural-capital-indicators/carbon-indicators';
export const METHODOLOGY_EXPLAINER_PAGE_BIODIVERSITY_INDICATORS =
  'https://science.landler.io/natural-capital-indicators/biodiversity-indicators';
export const SENTRY_DSN = import.meta.env.SENTRY_DSN as string;
export const METHODOLOGY_VERSIONS = {
  soilGrids: '2.0',
  worldCover: '2.0',
} as const;
export const UNANALYZABLE_PLOT_STATUS_TYPES = [PlotStatusEnum.invalid, PlotStatusEnum.new_plot, PlotStatusEnum.draft];
export const ONGOING_ANALYSIS_PROJECT_STATUS_TYPES = [ProjectStatus.scheduled_for_analysis, ProjectStatus.aggregating];
export const UNEDITABLE_PROJECT_TYPES = [ProjectStatus.locked, ProjectStatus.invested];
export const BUYER_CALENDLY_LINK = 'https://calendly.com/sophie-vanberchem/30min';
export const LANDSTEWARD_CALENDLY_LINK = 'https://calendly.com/sophie-vanberchem/30min';
export const HELP_EMAIL_ADDRESS = 'info@landler.io';
export const INVESTMENTS_PORTFOLIO_SUPPORT_EMAIL_ADDRESS_FOR_LANDSTEWARDS = 'sophie.vanberchem@thelandbankinggroup.com';
export const INVESTMENTS_PORTFOLIO_SUPPORT_EMAIL_ADDRESS_FOR_BUYERS = 'sophie.vanberchem@thelandbankinggroup.com';
export const SELF_SIGNUP_ENABLED = import.meta.env.SELF_SIGNUP_ENABLED === 'true';

export const complianceFrameworkLogos = ['science', 'greenhouse', 'sai', 'eudr', 'tnfd', 'rac'] as const;
export type ComplianceFrameworkLogo = (typeof complianceFrameworkLogos)[number];

export const COMPLIANCE_LOGOS_BIODIVERSITY: ComplianceFrameworkLogo[] = ['sai', 'eudr'];
export const COMPLIANCE_LOGOS_WATER: ComplianceFrameworkLogo[] = ['sai'];
export const COMPLIANCE_LOGOS_CARBON: ComplianceFrameworkLogo[] = ['science', 'greenhouse', 'sai'];
export const COMPLIANCE_LOGOS_ABOVE_GROUND_CARBON: ComplianceFrameworkLogo[] = ['science', 'greenhouse', 'sai', 'tnfd'];
export const COMPLIANCE_LOGOS_CANOPY_COVER: ComplianceFrameworkLogo[] = ['science', 'tnfd', 'rac'];
export const COMPLIANCE_LOGOS_CANOPY_HEIGHT: ComplianceFrameworkLogo[] = ['science', 'tnfd', 'rac'];

export const PRIVACY_POLICY_VERSION = '2023-04';

export const PLOT_MIN_AREA_IN_M2 = import.meta.env.PLOT_MIN_AREA_IN_M2 as string;
export const PLOT_MAX_AREA_IN_M2 = import.meta.env.PLOT_MAX_AREA_IN_M2 as string;
export const MAP_MAX_ZOOM = 16;

// TODO: MVP-3284 rename to MAP_OVERVIEW_PADDING and delete the old export
export const MAP_OVERVIEW_PADDING_MOBILE = {
  left: 20,
  right: 20,
  top: 20,
  bottom: 20,
} as const;
export const MAP_OVERVIEW_PADDING = {
  left: 56, // marker pin
  right: 56, // marker pin
  top: 170, // HUD
  bottom: 64, // half of bottom decoration
} as const;

export const PROJECT_TYPES_WITH_IMPACT_PROJECTIONS: PlotType[] = [PlotType.CROPLAND];
