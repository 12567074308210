import { Stack } from 'component-library';
import { Trans, useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { CapsuleTrend } from '@/components';
import { NCCardSectionHeader, printLatestDate, useNCCardContext } from '@/components/NCCard/NCCard';
import { getNCChangeIcon, getNCChangeIconVariant, NCFactCapsule } from '@/components/NCFactCapsule/NCFactCapsule';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { usePlotFact } from '@/pages/shared/hooks/usePlotFact';
import { formatFactKilogramsToTonnes } from '@/utils/formatting/formatKgToTon';

export const AboveCarbonCardStatsTile = () => {
  return (
    <Stack className='gap-8 px-6 py-8 lg:gap-4' data-testid='carbon-stats-tile'>
      <AboveGroundCarbonStorage />
    </Stack>
  );
};

// TODO: MVP-4772 - change with forest fact
// this includes all the other places where the BG carbon facts are used in this file

const AboveGroundCarbonStorage = () => {
  const { t } = useTranslation();

  const { analysisType } = useNCCardContext();

  const carbonAgLatestAnalysis = formatFactKilogramsToTonnes(
    usePlotFact<number>(
      analysisType === 'total' ? R1FactType.r1_carbon_storage_bg_total : R1FactType.r1_carbon_storage_bg_per_ha,
    ),
  );

  const carbonAg1yrChange = formatFactKilogramsToTonnes(
    usePlotFact<number>(
      analysisType === 'total'
        ? R1FactType.r1_carbon_bg_previous_1y_uplift_total
        : R1FactType.r1_carbon_bg_previous_1y_uplift_per_ha,
    ),
  );

  const carbonStorageAgTrend = usePlotFact<number>(
    analysisType === 'total'
      ? R1FactType.r1_carbon_bg_previous_1y_uplift_total_pct_change
      : R1FactType.r1_carbon_bg_previous_1y_uplift_per_ha_pct_change,
  );
  let carbonStorageAgTrendDisplay = `${useDisplayNumber(carbonStorageAgTrend?.value ?? 0, {
    signDisplay: 'always',
  })} %`;

  carbonStorageAgTrendDisplay = carbonStorageAgTrend?.value != null ? carbonStorageAgTrendDisplay : '-';

  const lastMeasuredDate = printLatestDate([carbonAgLatestAnalysis?.measured_at]);
  const carbonAg1yrChangeInitialMeasuredAt = lastMeasuredDate;
  const carbonAg1yrChangePreviousMeasuredAt = printLatestDate([carbonAg1yrChange?.measured_at]);

  return (
    <div className='flex flex-col gap-8 lg:gap-4'>
      <NCCardSectionHeader
        title={t('shared.ncaDetail.details.aboveGroundCarbon.labels.aboveGroundCarbonStorage')}
        supplementaryInfo={`${t('shared.ncaDetail.details.labels.updated')} ${lastMeasuredDate}`}
        popover={{
          title: t('shared.ncaDetail.details.aboveGroundCarbon.explainers.aboveGroundCarbonStorage.title'),
          body: t('shared.ncaDetail.details.aboveGroundCarbon.explainers.aboveGroundCarbonStorage.body'),
        }}
      />
      <Stack direction='row' className='flex-wrap gap-16'>
        <NCFactCapsule
          label={t('shared.ncaDetail.details.labels.latestAnalysis')}
          fact={carbonAgLatestAnalysis}
          trend={
            typeof carbonStorageAgTrend?.value === 'number' && (
              <CapsuleTrend variant={carbonStorageAgTrend.value < 0 ? 'negative' : 'positive'}>
                {carbonStorageAgTrendDisplay}
              </CapsuleTrend>
            )
          }
          capsuleIconProps={{
            icon: getNCChangeIcon(carbonAgLatestAnalysis),
            variant: getNCChangeIconVariant(carbonAgLatestAnalysis),
          }}
          infoPopoverProps={{
            title: t('shared.ncaDetail.details.explainers.latestAnalysis.title'),
            body: t('shared.ncaDetail.details.explainers.latestAnalysis.body', { date: lastMeasuredDate }),
            popoverTriggerProps: {
              className: 'text-xs',
            },
          }}
        />
        {!!carbonAg1yrChange?.value && (
          <NCFactCapsule
            label={t('shared.ncaDetail.details.labels.oneYearChange')}
            fact={carbonAg1yrChange}
            capsuleIconProps={{
              icon: getNCChangeIcon(carbonAg1yrChange),
              variant: getNCChangeIconVariant(carbonAg1yrChange),
            }}
            infoPopoverProps={{
              title: t('shared.ncaDetail.details.aboveGroundCarbon.explainers.oneYearChange.title'),
              body: t('shared.ncaDetail.details.aboveGroundCarbon.explainers.oneYearChange.body', {
                date: carbonAg1yrChangeInitialMeasuredAt,
                previousDate: carbonAg1yrChangePreviousMeasuredAt,
              }),
              popoverTriggerProps: {
                'data-testid': 'carbon-1yr-change-tooltip',
              },
            }}
          />
        )}
      </Stack>
      <Stack className='mt-4 gap-4 rounded-2xl bg-neutral-black-4 px-4 py-6'>
        <span className='typography-h4'>
          {t('shared.ncaDetail.details.aboveGroundCarbon.explainers.aboveGroundCarbonStorageDetails.title')}
        </span>
        <Trans
          i18nKey='shared.ncaDetail.details.aboveGroundCarbon.explainers.aboveGroundCarbonStorageDetails.body'
          values={{
            trendPercent: carbonStorageAgTrendDisplay,
          }}
          components={{
            p: <p className='typography-body1 text-text-secondary' />,
          }}
        />
      </Stack>
    </div>
  );
};
