import { Stack } from 'component-library';
import { useTranslation } from 'react-i18next';

import { BenchmarkType, PlotReportFactLabelNameEnum, R1FactType } from '@/api/rest/resources/types/fact';
import { AboveGroundCarbonStorageHistorical } from '@/components/Charts';
import { ChartHeading } from '@/components/Charts/components/ChartHeading';
import { GraphFact } from '@/components/Charts/types';
import { kgToTonneFormatter } from '@/components/Charts/utils';
import { useNCCardContext } from '@/components/NCCard/NCCard';
import { useBenchmarksForPlot } from '@/pages/shared/hooks/useBenchmarkForFact';
import { usePlotFact } from '@/pages/shared/hooks/usePlotFact';

import { NoPlotData } from '../NoPlotData';

export const AboveGroundCarbonCardGraphTile = () => {
  const { t } = useTranslation();
  const { analysisType } = useNCCardContext();
  // TODO: MVP-4772 Integrate with BE (switch facts from using carbon to forest)

  const carbonAgHistoric = usePlotFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.r1_soil_carbon_historic_total_graph
      : R1FactType.r1_soil_carbon_historic_per_ha_graph,
  );
  const carbonAgHistoricData = carbonAgHistoric?.value;

  const carbonAgLowBenchmark = usePlotFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.permanent_soil_carbon_total_benchmark_graph
      : R1FactType.permanent_soil_carbon_per_ha_benchmark_graph,
    null,
    { [PlotReportFactLabelNameEnum.benchmark]: BenchmarkType.low },
  );
  const cagLowBenchmarkData = (carbonAgLowBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_low` };
  });
  const carbonAgModerateBenchmark = usePlotFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.permanent_soil_carbon_total_benchmark_graph
      : R1FactType.permanent_soil_carbon_per_ha_benchmark_graph,
    null,
    {
      [PlotReportFactLabelNameEnum.benchmark]: BenchmarkType.moderate,
    },
  );
  const cagModerateBenchmarkData = (carbonAgModerateBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_moderate` };
  });
  const carbonHighBenchmark = usePlotFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.permanent_soil_carbon_total_benchmark_graph
      : R1FactType.permanent_soil_carbon_per_ha_benchmark_graph,
    null,
    {
      [PlotReportFactLabelNameEnum.benchmark]: BenchmarkType.high,
    },
  );
  const cagHighBenchmarkData = (carbonHighBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_high` };
  });

  const cagBenchmarks = useBenchmarksForPlot(
    analysisType === 'total'
      ? R1FactType.permanent_soil_carbon_total_benchmark_graph
      : R1FactType.permanent_soil_carbon_per_ha_benchmark_graph,
    null,
    kgToTonneFormatter,
  );

  const noChartData = !carbonAgHistoricData?.length;
  if (noChartData) {
    return <NoPlotData />;
  }

  const chartData = [
    ...carbonAgHistoricData,
    ...cagLowBenchmarkData,
    ...cagModerateBenchmarkData,
    ...cagHighBenchmarkData,
  ];

  return (
    <Stack center data-testid='carbon-graph-tile'>
      <Stack spacing={2.5} className='w-full items-center justify-center px-1 py-6 lg:items-start lg:p-8 lg:pl-1'>
        <ChartHeading
          heading={t('shared.ncaDetail.details.carbon.labels.historicalAboveGroundGraphHeadline')}
          popover={{
            title: t('shared.ncaDetail.details.aboveGroundCarbon.explainers.HistoricalAboveGroundCarbonStorage.title'),
            body: t('shared.ncaDetail.details.aboveGroundCarbon.explainers.HistoricalAboveGroundCarbonStorage.body'),
          }}
          className='w-full px-6 sm:px-0'
        />
        <AboveGroundCarbonStorageHistorical
          analysisType={analysisType}
          data={chartData}
          benchmarks={cagBenchmarks}
          showTooltip={true}
          className='relative'
          data-testid='above-ground-carbon-storage-historic-chart'
        />
      </Stack>
    </Stack>
  );
};
