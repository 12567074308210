import { Column, Row } from '@tanstack/react-table';
import { ForestryIcon } from 'component-library';
import { useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { PlotType } from '@/api/rest/resources/types/plot';
import { UnitEnum } from '@/api/rest/resources/types/units';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';

import { FactCell } from '../../../components/plot-list/Cells';
import {
  BiodiversityZoneCell,
  CarbonStorageAgCell,
  CarbonStorageBgCell,
  PlotRow,
  WaterHoldingCapacityCell,
} from '../components/Cells';
import { ToggleSortButton } from '../components/ToggleSortButton';

export enum ProjectTypeAccessorKey {
  carbonStorageAg = 'carbonStorageAg',
  carbonStorageBg = 'carbonStorageBg',
  waterHoldingCapacity = 'waterHoldingCapacity',
  canopyHeight = 'canopyHeight',
  canopyCover = 'canopyCover',
  biodiversityZone = 'biodiversityZone',
}

type CellHeader = {
  column: Column<PlotRow, unknown>;
};

type CellProps = {
  row: Row<PlotRow>;
};

export const useGetProjectTypeCells = () => {
  const project = useProjectDetailById().data;
  const { t } = useTranslation();

  const isForestProject = project.landtypes_allowed.includes(PlotType.TREE_COVER); // TODO: MVP-4724 - swap out with PlotType.FORESTRY

  if (isForestProject) {
    return [
      {
        accessorKey: ProjectTypeAccessorKey.carbonStorageAg,
        header: ({ column }: CellHeader) => (
          <ToggleSortButton column={column} className='-ml-2.5'>
            {t('shared.plots.tableHeader.carbonStorageAG')}
          </ToggleSortButton>
        ),
        cell: CarbonStorageAgCell,
      },
      {
        accessorKey: ProjectTypeAccessorKey.waterHoldingCapacity,
        header: ({ column }: CellHeader) => (
          <ToggleSortButton column={column} className='-ml-2.5'>
            {t('shared.plots.tableHeader.waterHoldingCapacity')}
          </ToggleSortButton>
        ),
        cell: WaterHoldingCapacityCell,
      },
      {
        accessorKey: ProjectTypeAccessorKey.canopyHeight,
        header: ({ column }: CellHeader) => (
          <ToggleSortButton column={column} className='-ml-2.5'>
            {t('shared.plots.tableHeader.canopyHeight')}
          </ToggleSortButton>
        ),
        cell: ({ row }: CellProps) => {
          return (
            <FactCell
              plot={row.original}
              factType={R1FactType.r1_carbon_storage_bg_per_ha} // TODO: MVP-4772 update with forest fact
              unitType={UnitEnum.m}
              icon={ForestryIcon}
            />
          );
        },
      },
      {
        accessorKey: ProjectTypeAccessorKey.canopyCover,
        header: ({ column }: CellHeader) => (
          <ToggleSortButton column={column} className='-ml-2.5'>
            {t('shared.plots.tableHeader.canopyCover')}
          </ToggleSortButton>
        ),
        cell: ({ row }: CellProps) => {
          return (
            <FactCell
              plot={row.original}
              factType={R1FactType.r1_carbon_storage_bg_per_ha} // TODO: MVP-4772 update with forest fact
              unitType={UnitEnum['%']}
              icon={ForestryIcon}
            />
          );
        },
      },
    ];
  }

  return [
    {
      accessorKey: ProjectTypeAccessorKey.biodiversityZone,
      header: ({ column }: CellHeader) => (
        <ToggleSortButton column={column} className='-ml-2.5'>
          {t('shared.plots.tableHeader.protectedHabitat')}
        </ToggleSortButton>
      ),
      cell: BiodiversityZoneCell,
    },
    {
      accessorKey: ProjectTypeAccessorKey.carbonStorageBg,
      header: ({ column }: CellHeader) => (
        <ToggleSortButton column={column} className='-ml-2.5'>
          {t('shared.plots.tableHeader.carbonStorageBG')}
        </ToggleSortButton>
      ),
      cell: CarbonStorageBgCell,
    },
    {
      accessorKey: ProjectTypeAccessorKey.waterHoldingCapacity,
      header: ({ column }: CellHeader) => (
        <ToggleSortButton column={column} className='-ml-2.5'>
          {t('shared.plots.tableHeader.waterHoldingCapacity')}
        </ToggleSortButton>
      ),
      cell: WaterHoldingCapacityCell,
    },
  ];
};
