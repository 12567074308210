import { InfoPopoverProps } from 'component-library';
import { cn } from 'component-library/utils';
import React, { HTMLAttributes } from 'react';

import { FactLike } from '@/api/rest/resources/conservation';
import {
  Capsule,
  CapsuleContent,
  CapsuleContentProps,
  CapsuleIcon,
  CapsuleIconProps,
  CapsuleLabel,
  CapsuleTrend,
} from '@/components';
import { getDisplayNumber } from '@/hooks/useDisplayNumber';

import { useFactDisplay } from '../hooks/useFactDisplay';

export type FactCapsuleProps = {
  fact: FactLike;
  label: React.ReactNode;
  signed?: ValueFromFactProps['signed'];
  trend?: ValueFromFactProps['trend'];
  infoPopoverProps?: InfoPopoverProps | null;
  capsuleIconProps?: CapsuleIconProps;
};

export const FactCapsule = ({ fact, label, trend, signed, infoPopoverProps, capsuleIconProps }: FactCapsuleProps) => {
  return (
    <Capsule
      label={<CapsuleLabel infoPopoverProps={infoPopoverProps}>{label}</CapsuleLabel>}
      content={<ValueFromFact fact={fact} trend={trend} signed={signed} />}
      thumbnail={<CapsuleIcon {...capsuleIconProps} />}
    />
  );
};

type ValueFromFactProps = HTMLAttributes<HTMLDivElement> & {
  fact?: FactLike;
  trend?: CapsuleContentProps['trend'];
  signed?: boolean;
};

const ValueFromFact: React.FC<ValueFromFactProps> = ({ fact, trend, className, signed }) => {
  const { value, display } = useFactDisplay(fact, { signed });

  if (value == null) {
    return <CapsuleContent className={cn('text-text-disabled', className)}>-</CapsuleContent>;
  }

  return (
    <CapsuleContent className={className} trend={trend}>
      {display}
    </CapsuleContent>
  );
};

export const FactCapsuleTrend = ({ trend: trendInput }: { trend?: FactLike | number }) => {
  if (!trendInput) {
    return null;
  }

  const trend = 'value' in (trendInput as FactLike) ? (trendInput as FactLike).value : trendInput;

  if (!trend) {
    return null;
  }

  const trendDisplay = `${getDisplayNumber(trend, undefined, {
    signDisplay: 'always',
  })}%`;

  const variant = trend < 0 ? 'negative' : 'positive';

  return <CapsuleTrend variant={variant}>{trendDisplay}</CapsuleTrend>;
};
