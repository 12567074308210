import { Stack } from 'component-library';
import { useTranslation } from 'react-i18next';

import { BenchmarkType, PlotReportFactLabelNameEnum, R1FactType } from '@/api/rest/resources/types/fact';
import { WaterHistoricalChart } from '@/components/Charts';
import { ChartHeading } from '@/components/Charts/components/ChartHeading';
import { GraphFact } from '@/components/Charts/types';
import { useNCCardContext } from '@/components/NCCard/NCCard';
import { useBenchmarksForProject } from '@/pages/shared/hooks/useBenchmarkForFact';
import { useProjectFact } from '@/pages/shared/hooks/useProjectFact';

import { NoProjectData } from '../../../../components/NoProjectData';

export const WaterCardGraphTile = () => {
  const { t } = useTranslation();
  const { analysisType } = useNCCardContext();

  const historicWaterAndSoilMoisture = useProjectFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.r1_historic_water_and_soil_moisture_graph
      : R1FactType.r1_historic_water_and_soil_moisture_per_ha_graph,
  );
  const whcData = historicWaterAndSoilMoisture?.value?.filter(
    (fact) => !['soil_moisture', 'soil_moisture_per_ha'].includes(fact?.name),
  ); // Soil moisture should not be displayed at project level

  const whcLowBenchmark = useProjectFact<GraphFact[]>(
    analysisType === 'total' ? R1FactType.water_fc_total_benchmark_graph : R1FactType.water_fc_per_ha_benchmark_graph,
    null,
    { [PlotReportFactLabelNameEnum.benchmark]: BenchmarkType.low },
  );
  const whcLowBenchmarkData = (whcLowBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_low` };
  });
  const whcModerateBenchmark = useProjectFact<GraphFact[]>(
    analysisType === 'total' ? R1FactType.water_fc_total_benchmark_graph : R1FactType.water_fc_per_ha_benchmark_graph,
    null,
    {
      [PlotReportFactLabelNameEnum.benchmark]: BenchmarkType.moderate,
    },
  );
  const whcModerateBenchmarkData = (whcModerateBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_moderate` };
  });
  const whcHighBenchmark = useProjectFact<GraphFact[]>(
    analysisType === 'total' ? R1FactType.water_fc_total_benchmark_graph : R1FactType.water_fc_per_ha_benchmark_graph,
    null,
    {
      [PlotReportFactLabelNameEnum.benchmark]: BenchmarkType.high,
    },
  );
  const whcHighBenchmarkData = (whcHighBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_high` };
  });

  const whcBenchmarks = useBenchmarksForProject(
    analysisType === 'total' ? R1FactType.water_fc_total_benchmark_graph : R1FactType.water_fc_per_ha_benchmark_graph,
  );

  const noChartData = !whcData?.length;

  if (noChartData) {
    return <NoProjectData />;
  }

  const chartData = [...whcData, ...whcLowBenchmarkData, ...whcModerateBenchmarkData, ...whcHighBenchmarkData];

  return (
    <Stack center data-testid='water-historic-graph-tile'>
      <Stack spacing={2.5} className='w-full items-center justify-center px-1 py-6 lg:items-start lg:p-6 lg:pl-1'>
        <ChartHeading
          heading={t('shared.ncaDetail.details.water.labels.historicalWHCGraphHeadline')}
          popover={{
            title: t('shared.ncaDetail.details.water.explainers.historicalData.title'),
            body: t('shared.ncaDetail.details.water.explainers.historicalData.body'),
          }}
          className='w-full px-6 sm:px-0'
        />
        <WaterHistoricalChart
          analysisType={analysisType}
          data={chartData}
          benchmarks={whcBenchmarks}
          showTooltip={true}
          className='relative'
          data-testid='water-historical-chart'
        />
      </Stack>
    </Stack>
  );
};
