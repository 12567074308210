import { useTranslation } from 'react-i18next';

import { NCCard, NCCard2SectionLayout, NCCardComplianceFooter, NCCardHeader } from '@/components/NCCard/NCCard';
import { CanopyCoverExplainer } from '@/components/NCExplainers/CanopyCoverExplainer';
import { COMPLIANCE_LOGOS_CANOPY_COVER } from '@/config/constants';

import { CanopyCoverGraphTile } from './GraphTile';
import { CanopyCoverCardStatsTile } from './StatsTile';

export const CanopyCover = () => {
  const { t } = useTranslation();

  return (
    <NCCard explainer={<CanopyCoverExplainer />}>
      <NCCardHeader title={t('shared.ncaDetail.details.forest.labels.canopyCover')} />
      <NCCard2SectionLayout>
        <CanopyCoverGraphTile />
        <CanopyCoverCardStatsTile />
      </NCCard2SectionLayout>
      <NCCardComplianceFooter logos={COMPLIANCE_LOGOS_CANOPY_COVER} />
    </NCCard>
  );
};
