import { useParams } from 'react-router-dom';

import { PlotReportFactLabelFilter, R1FactType } from '@/api/rest/resources/types/fact';

import { useDashboardByProjectId } from './useDashboardByProjectId';
import { filterValidLabels, filterValidLabelsForScenario, getFact } from './utils';

/**
 * @param projectId Falls back to the projectId in the URL if no value is provided.
 */
export const useProjectFact = <T = unknown>(
  type: R1FactType,
  projectIdArg?: string | null,
  labelFilters?: PlotReportFactLabelFilter,
) => {
  const routeParams = useParams();
  const projectId = projectIdArg ?? routeParams.projectId;

  if (!projectId) {
    throw new Error('Missing URL param: projectId');
  }

  // Since BE ignore label filters for benchmark and landuse, we filter those out before hitting the API to avoid multiple duplicate calls
  const plotReportFactLabelFilters = labelFilters ? filterValidLabelsForScenario(labelFilters) : {};
  const dashboardData = useDashboardByProjectId(projectId, plotReportFactLabelFilters).data;

  // But we pass all filters to getFact to fetch the right fact from the API response (eg: choose between high/low/mod benchmark facts)
  const labels = labelFilters ? filterValidLabels(labelFilters) : {};
  return getFact<T>(dashboardData, type, labels);
};
