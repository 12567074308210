import { Pill } from '@landler/component-library';
import { Icon, IconKey } from '@landler/deprecated-component-library';

import { PlotReportFactElement, R1FactType } from '@/api/rest/resources/types/fact';
import { withSuspenseBoundary } from '@/components';
import { PendingPill } from '@/components/Pill/PendingPill';
import { useFact } from '@/hooks/useFact';
import { useProjectFact } from '@/pages/shared/hooks/useProjectFact';

export const FactPill = withSuspenseBoundary(
  ({
    projectId,
    factType,
    iconName,
    formatter,
  }: {
    projectId: string;
    factType: R1FactType;
    iconName: IconKey;
    formatter?: (fact?: PlotReportFactElement) => PlotReportFactElement | undefined;
  }) => {
    const projectFact = useProjectFact(factType, projectId);
    const fact = useFact(formatter ? formatter(projectFact) : projectFact);
    return (
      <Pill
        size='small'
        leftAdornment={<Icon icon={iconName} width={12} height={12} />}
        className='bg-neutral-black-4'
        data-cy='fact-pill'
      >
        {fact.display}
      </Pill>
    );
  },
  <PendingPill />,
);
