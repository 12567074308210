import { Loader } from 'component-library';

import { withSuspenseBoundary } from '@/components';
import { useScreenSize } from '@/hooks/useScreenSize';

import { PlotListDesktop } from './components/PlotListDesktop';
import { PlotListMobile } from './components/PlotListMobile';

export const PlotsPage = withSuspenseBoundary(
  () => {
    const isSmallScreen = useScreenSize() === 'small';

    if (isSmallScreen) return <PlotListMobile />;

    return <PlotListDesktop />;
  },
  <Loader className='min-h-[300px]' />,
);
