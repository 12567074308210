import { useEffect, useRef } from 'react';

/**
 * **ANTI-PATTERN! AVOID USING THIS!**
 *
 * If you find yourself reaching out for this, there are probably other ways to
 * structure your code. @see https://react.dev/learn/you-might-not-need-an-effect.
 *
 * Ensures that the effect is executed only once. The dependency array will be
 * ignored.
 */
export const useEffectOnce = (...[callback, ...delegated]: Parameters<typeof useEffect>) => {
  const hasEffectRunRef = useRef(false);

  useEffect(
    () => {
      if (hasEffectRunRef.current) {
        return undefined;
      }

      hasEffectRunRef.current = true;

      return callback();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...delegated,
  );
};
