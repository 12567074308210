import { NoProjectData } from '../../../components/NoProjectData';
import { NoPlotData } from '../../plot/components/NoPlotData';
import { useIsLandPortionProject } from '../hooks/useIsLandPortionProject';
import { useScenarioForm } from '../hooks/useScenarioForm';

export const NoData = () => {
  const form = useScenarioForm();
  const [landPortionId] = form.getValues(['landPortionId']);

  const isLandPortionProject = useIsLandPortionProject();

  return isLandPortionProject ? <NoProjectData /> : <NoPlotData plotIdArg={landPortionId} />;
};
